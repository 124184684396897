import React, {useContext, useEffect, useState} from "react";
import IODefinitionStore from "../../stores/IODefinitionStore";
import Helper from "../../library/Helper";
import BasicFilterInput from "../../library/BasicFilterInput";
import ButtonGlyph from "../../library/buttonGlyph";
import {AuthContext} from "../../ResponsiveApp";
import Triggers from "../../api/triggerApi";
import TriggerBuilder from "../../library/TriggerBuilder";
import HttpCodes from "../../library/HttpCodes";



const numericOperators = [
    {key: "EQ", label: "Equal to"},
    {key: "NE", label: "Not Equal to"},
    {key: "GT", label: "Greater Than"},
    {key: "GE", label: "Greater Than or Equal to"},
    {key: "LT", label: "Less Than"},
    {key: "LE", label: "Less Than or Equal to"},
]

const textOperators = [
    {key: "EQ", label: "Equal to"},
    {key: "NE", label: "Not Equal to"},
    {key: "LIKE", label: "Includes"},
    {key: "NOT_LIKE", label: "Does Not Include"},
]

const TriggerEditor = ({targetTrigger, ioDefinitions, fwRevisions, onEditComplete}) => {
    const {authorisation} = useContext(AuthContext)

    const [selectedFW, setSelectedFW] = useState(null) ;

    const [adcAItems, setAdcAItems] = useState([]) ;
    const [adcBItems, setAdcBItems] = useState([]) ;
    const [adcCItems, setAdcCItems] = useState([]) ;
    const [adcOutItems, setAdcOutItems] = useState([]) ;
    const [analogueItems, setAnalogueItems] = useState([]) ;

    const [displayGroups, setDisplayGroups] = useState(true) ;
    const [displayAdcItems, setDisplayAdcItems] = useState(true) ;
    const [displayAnalogueItems, setDisplayAnalogueItems] = useState(true) ;
    const [filterText, setFilterText] = useState("") ;

    const [editTrigger, setEditTrigger] = useState(null) ;
    const [editName, setEditName] = useState("") ;
    const [editDesc, setEditDesc] = useState("") ;
    const [editActive, setEditActive] = useState(false) ;
    const [editLocked, setEditLocked] = useState(false) ;
    const [editDeleted, setEditDeleted] = useState(false) ;
    const [editGlobal, setEditGlobal] = useState(false) ;
    const [editIsHeatbeat, setEditIsHeartbeat] = useState(false) ;
    const [editAllowNotify, setEditAllowNotify] = useState(true) ;
    const [allowFirmwareChange, setAllowFirmwareChange] = useState(true) ;

    useEffect(() => {
        console.log("Edit Trigger -> ", targetTrigger) ;
        console.log("Firmware Revisions -> ", fwRevisions) ;
        if (targetTrigger) {
            setEditName(targetTrigger.trigger_name) ;
            setEditDesc(targetTrigger.trigger_desc) ;
            setEditActive(targetTrigger.is_active) ;
            setEditLocked(targetTrigger.is_locked) ;
            setEditDeleted(targetTrigger.is_deleted) ;
            setEditGlobal(targetTrigger.is_global) ;
            setEditIsHeartbeat(targetTrigger.is_heartbeat) ;
            setEditAllowNotify(targetTrigger.should_notify) ;

            let fw = fwRevisions.find((fw) => fw.revision === targetTrigger.firmware_target) ;
            setSelectedFW(fw) ;
            buildSelectedFwView(fw) ;
            setAllowFirmwareChange(targetTrigger.trigger_id === null) ;
        }
        else {
            setEditName("") ;
            setEditDesc("") ;
            setEditActive(false) ;
            setEditLocked(false) ;
            setEditDeleted(false) ;
            setEditGlobal(false) ;
            setEditIsHeartbeat(false) ;
            setEditAllowNotify(true) ;
            setAllowFirmwareChange(true) ;
        }
        setEditTrigger(targetTrigger) ;
    }, [targetTrigger]);


    const buildSelectedFwView = (fw) => {
        if (editTrigger && editTrigger.trigger_id) {
            // do not allow changing firmware if this is an existing trigger
            return ;
        }

        setSelectedFW(fw) ;
        if (fw) {
            let am = Object.values(fw.item_adc_map) ;
            let adcA = am.filter((a) => a.adc === "A") ;
            let adcB = am.filter((a) => a.adc === "B") ;
            let adcC = am.filter((a) => a.adc === "C") ;
            let adcOut = am.filter((a) => a.adc === "O") ;

            setAdcAItems(adcA) ;
            setAdcBItems(adcB) ;
            setAdcCItems(adcC) ;
            setAdcOutItems(adcOut) ;

            setAnalogueItems(Object.values(fw.analogue_items)) ;
            if (!editIsHeatbeat) {
                console.log("Setting firmware target to ", fw.revision) ;
                let et = {...editTrigger} ;
                et.firmware_target = fw.revision ;
                setEditTrigger(et) ;
            }
        }
    }


    const lookupAdcItemForSelectedFw = (key) => {
        if (!ioDefinitions || !selectedFW) {
            return null ;
        }

        let adcItems = selectedFW.item_adc_map ;
        return adcItems[key] ;
    }


    const handleFilter = (filterText) => {
        console.log('Filter text:', filterText);
        setFilterText(filterText.toLowerCase()) ;
    }

    const cancelEditMode = () => {
        setEditName("") ;
        setEditDesc("") ;
        onEditComplete({cancelled : true}) ;
    }


    const saveEditTrigger = async () => {
        try {
            let context = {
                expr_stack : [],
                errors : null
            }

            console.log("Edit Trigger RAW -> ", editTrigger) ;
            TriggerBuilder(editTrigger.raw_structure, context) ;

            console.log("Expression Stack -> ", context.expr_stack) ;
            let expr = context.expr_stack.pop() ;
            console.log("Expression -> ", expr) ;
            console.log("Selected Firmware -> ", selectedFW) ;

            if (!editTrigger.firmware_target && !editIsHeatbeat) {
                editTrigger.firmware_target = selectedFW.revision ;
            }

            let trigger_update = {
                trigger_id : editTrigger.trigger_id,
                firmware: (!editIsHeatbeat) ? editTrigger.firmware_target : null,
                heartbeat: editIsHeatbeat,
                active: editActive,
                global: editGlobal,
                locked: editLocked,
                deleted: editDeleted,
                notify: editAllowNotify,
                name: editName,
                desc: editDesc,
                valid: true,
                structure: editTrigger.raw_structure,
                expression : expr,
                timer: editTrigger.timer
            }

            console.log("Trigger Update -> ", trigger_update);
            let result = await Triggers.save(trigger_update);
            console.log("Trigger Save Result -> ", result);
            if (result.status === HttpCodes.HttpOK) {
                onEditComplete({cancelled : false, trigger_id: result.trigger_id}) ;
            }

        } catch (e) {
            console.log("Error saving trigger", e);
        } finally {
            // setEditTrigger(null) ;
            // setEditMode(false) ;
        }
    }

    const addEditItem = (item, item_type) => {
        if (!item) {
            return ;
        }

        // console.log("Edit Item Selected - ", item) ;
        let rules = editTrigger.raw_structure ;
        if (rules.length === 0) {
            // Add in a parenthesis rule
            let rule = {
                type: "LEFT_PAREN",
                applied : null
            }

            rules.push(rule) ;
        }
        else {
            let last_rule = rules[rules.length - 1] ;
            last_rule.type = "BOTH_PAREN" ;

            let join_rule = {
                type: "JOIN",
                applied : "AND"
            }

            rules.push(join_rule) ;

            let both_rule = {
                type: "BOTH_PAREN",
                applied : null
            }

            rules.push(both_rule) ;
        }

        let adc_rule = {
            type: item_type,
            value : null,
            target: item,
        }

        rules.push(adc_rule) ;

        let rule = {
            type: "RIGHT_PAREN",
            applied : null
        }

        rules.push(rule) ;

        setEditTrigger({...editTrigger, rules: rules, item_count: editTrigger.item_count + 1}) ;
    }

    const removeTriggerItem = (index, item_type) => {
        let rules = editTrigger.raw_structure ;
        let rule = rules[index] ;
        if (!rule.type === item_type) {
            return ;
        }

        // if this is the first item and there is only one item, clear the rule items
        if (editTrigger.item_count === 1) {
            setEditTrigger({...editTrigger, rules: [], item_count: 0}) ;
            return
        }

        // if this is the first item and there are more items, remove the ADC item and the replace the both paren with a left paren
        if (index === 1) {
            rules.splice(index, 4) ;
            setEditTrigger({...editTrigger, rules: rules, item_count: editTrigger.item_count - 1}) ;
            return ;
        }

        // if this is the last rule just remove the preceding both paren, join rule, both paren and the adc rule
        if (index === rules.length - 2) {
            rules.splice(index - 3, 4) ;
            setEditTrigger({...editTrigger, rules: rules, item_count: editTrigger.item_count - 1}) ;
            return ;
        }

        // Ok, this is a middle rule, remove the both paren, join rule, both paren and the adc rule
        rules.splice(index - 3, 4) ;
        setEditTrigger({...editTrigger, rules: rules}) ;
    }

    const setEditValue = (index, value) => {
        let rules = editTrigger.raw_structure ;
        let rule = rules[index] ;
        rule.value = value ;
        setEditTrigger({...editTrigger, rules: rules}) ;
    }

    const setEditOperator = (index, op) => {
        let rules = editTrigger.raw_structure ;
        let rule = rules[index] ;
        rule.operator = op ;
        setEditTrigger({...editTrigger, rules: rules}) ;
    }

    const chooseJoin = (index, join) => {
        let rules = editTrigger.raw_structure ;
        let rule = rules[index] ;
        if (!rule.type === "JOIN") {
            return ;
        }
        rule.applied = join ;
        setEditTrigger({...editTrigger, rules: rules}) ;
    }

    const chooseParen = (index, paren) => {
        let rules = editTrigger.raw_structure ;
        let rule = rules[index] ;
        if (!rule.type === "LEFT_PAREN" || !rule.type === "RIGHT_PAREN" || !rule.type === "BOTH_PAREN") {
            return ;
        }

        if (paren === rule.applied) {
            rule.applied = null ;
        }
        else {
            rule.applied = paren ;
        }

        setEditTrigger({...editTrigger, rules: rules}) ;
    }

    const setFieldValue = (idx, item, value) => {
        console.log("Setting value for item", idx, item, value) ;
    }

    const setTimer = () => {
        let timer = editTrigger.timer ;
        if (timer) {
            timer = null ;
        }
        else {
            timer = {
                unit : 'minutes',
                value : 1
            }
        }

        setEditTrigger({...editTrigger, timer : timer}) ;
    }

    const setTimerValue = (value) => {
        let timer = editTrigger.timer ;
        timer.value = value ;
        setEditTrigger({...editTrigger, timer : timer}) ;
    }

    const setTimerUnit = (unit) => {
        let timer = editTrigger.timer ;
        timer.unit = unit ;
        setEditTrigger({...editTrigger, timer : timer}) ;
    }

    const analogueEditor = (idx, item) => {
        let an = item.target ;

        let body = <></>
        if (an.is_lookup) {
            body = (
                <ul className={"lookup-list"} style={{marginLeft: '20px', paddingTop: '10px'}}>
                    {Object.keys(an.lookup).map((k, kidx) => {
                        let lu = an.lookup[k];
                        let scc = lu === item.value ? "fw-hl-cell-sel" : "fw-hl-cell"
                        return (
                            <li key={kidx} onClick={() => setEditValue(idx, lu)}><div className={scc}>[{k}] - {lu || "- no description -"}</div></li>
                        )
                    })
                    }

                </ul>
            )
        }
        else {
            switch (an.datatype) {
                case "int":
                    body = (
                        <div className="section-edit-row">
                            <select style={{marginRight: '10px'}} value={item.operator || ""} onChange={(e) => setEditOperator(idx, e.target.value)}>
                                {numericOperators.map((op, opidx) => {
                                    return (
                                        <option key={opidx} value={op.key}>{op.label}</option>
                                    )
                                }
                                )}
                            </select>

                            <input type={"number"}
                                   style={{width: '100px'}}
                                   value={item.value || ""}
                                   onChange={(e) => setEditValue(idx, e.target.value)}
                            />
                        </div>
                    )
                    break ;
                default :
                    body = (
                        <div className="section-edit-row">
                            <label style={{paddingRight: '10px', display: 'inline-block'}}>Value</label>
                            <input type={"text"}
                                   style={{width: '100px'}}
                                   value={item.value || ""}
                                   onChange={(e) => setEditValue(idx, e.target.value)}
                            />
                        </div>
                    )
                    break ;
            }
        }

        return (
            <div style={{marginLeft: '15px'}}>
                <div key={idx} style={{padding: '10px'}} className={"fw-item-editing"}>
                    <div className={"fw-item-title"}>{an.label}</div>

                    {body}

                    <div style={{paddingTop: '10px'}}>
                        <span className="material-icons" style={{color: 'red', fontSize: '32px', cursor: 'pointer'}} onClick={() => removeTriggerItem(idx, "ANALOGUE")}>delete</span>
                        <div className={"fw-item-bit"} style={{display: 'inline-block', float: 'right'}}>
                            <div className={"fw-item-desc"}>{an.key}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div>
                <button className={"button button-cancel"} onClick={cancelEditMode}><span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px'}}>cancel</span>Cancel Edit</button>
                <button className={"button button-save"} onClick={saveEditTrigger}><span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px'}}>save</span>Save</button>
            </div>

            {(allowFirmwareChange) &&
                <div>
                    <div className="section-header">Firmware Revisions</div>
                    <div style={{padding: '5px'}}>
                        <div className="item-container-list">
                            {fwRevisions.map((item, idx) => {
                                let selected = selectedFW && selectedFW.revision === item.revision;
                                let cl = selected ? "item-container item-container-selected" : "item-container";
                                return (
                                    <div key={idx} className={cl} onClick={() => buildSelectedFwView(item)}>
                                        <div>
                                            <div style={{padding: '2px'}}><b>{item.revision}</b></div>
                                            <div>{item.revision_name || "- no description -"}</div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            }

            {(editTrigger) &&
                <div>
                    <div className="section-header">Trigger Detail</div>
                    <div className="section-edit-row">
                        <label style={{width: '110px', display: 'inline-block'}}>Trigger Name</label>
                        <input type="text"
                               style={{width: '300px'}}
                               placeholder="rule name"
                               value={editName}
                               onChange={(e) => setEditName(e.target.value)}
                        />
                    </div>
                    <br/>
                    <div className="section-edit-row">
                        <label style={{width: '110px', display: 'inline-block'}}>Description</label>
                        <textarea
                            style={{width: '400px', height: '80px', verticalAlign: 'top', border: '0.5px solid darkgray'}}
                            placeholder="rule description"
                            value={editDesc}
                            onChange={e => setEditDesc(e.target.value)}/>
                    </div>

                    {(authorisation.isAdmin) &&
                        <div className="section-edit-row">
                            <label style={{width: '110px', display: 'inline-block'}}></label>
                            <div style={{marginLeft: '20px', marginTop: '10px', display: 'inline-block', width: '150px'}} onClick={() => setEditIsHeartbeat(!editIsHeatbeat)}>
                                <ButtonGlyph glyph={editIsHeatbeat ? "check_box" : "check_box_outline_blank"}
                                             cn="material-symbols-rounded"
                                             style={{fontSize: '24px', verticalAlign: 'middle', color: 'darkgray', width: '40px'}}/>
                                <small><i>Heartbeat Trigger</i></small>
                            </div>
                        </div>
                    }

                    {(authorisation.isAdmin || (!editGlobal && authorisation.isAdmin)) &&
                        <div className="section-edit-row">
                            <label style={{width: '110px', display: 'inline-block'}}></label>
                            <div style={{marginLeft: '20px', marginTop: '10px', display: 'inline-block', width: '150px'}} onClick={() => setEditActive(!editActive)}>
                                <ButtonGlyph glyph={editActive ? "check_box" : "check_box_outline_blank"}
                                             cn="material-symbols-rounded"
                                             style={{fontSize: '24px', verticalAlign: 'middle', color: 'darkgray', width: '40px'}}/>
                                <small><i>Active</i></small>
                            </div>
                            <div style={{marginLeft: '20px', marginTop: '10px', display: 'inline-block', width: '150px'}} onClick={() => setEditLocked(!editLocked)}>
                                <ButtonGlyph glyph={editLocked ? "check_box" : "check_box_outline_blank"}
                                             cn="material-symbols-rounded"
                                             style={{fontSize: '24px', verticalAlign: 'middle', color: 'darkgray', width: '40px'}}/>
                                <small><i>Locked</i></small>
                            </div>

                            <div style={{marginLeft: '20px', marginTop: '10px', display: 'inline-block'}} onClick={() => setEditDeleted(!editDeleted)}>
                                <ButtonGlyph glyph={editDeleted ? "check_box" : "check_box_outline_blank"}
                                             cn="material-symbols-rounded"
                                             style={{fontSize: '24px', verticalAlign: 'middle', color: 'darkgray', width: '40px'}}/>
                                <small><i>Deleted</i></small>
                            </div>
                        </div>
                    }


                    <div className="section-edit-row">
                        <label style={{width: '110px', display: 'inline-block'}}></label>

                        {(authorisation.isAdmin && !editIsHeatbeat) &&
                            <div style={{marginLeft: '20px', marginTop: '10px', display: 'inline-block', width: '150px'}} onClick={() => setEditGlobal(!editGlobal)}>
                                <ButtonGlyph glyph={editGlobal ? "check_box" : "check_box_outline_blank"}
                                             cn="material-symbols-rounded"
                                             style={{fontSize: '24px', verticalAlign: 'middle', color: 'darkgray', width: '40px'}}/>
                                <small><i>Global Trigger</i></small>
                            </div>
                        }
                        <div style={{marginLeft: '20px', marginTop: '10px', display: 'inline-block', width: '180px'}} onClick={() => setEditAllowNotify(!editAllowNotify)}>
                            <ButtonGlyph glyph={editAllowNotify ? "check_box" : "check_box_outline_blank"}
                                         cn="material-symbols-rounded"
                                         style={{fontSize: '24px', verticalAlign: 'middle', color: 'darkgray', width: '40px'}}/>
                            <small><i>Enable Notifications</i></small>
                        </div>

                    </div>

                    <br/>

                    <div className="section-header">Rule Definition</div>
                    <div style={{padding: '5px'}}>
                        <div className="item-container-list">
                            <div key={"e_tr"} className={"timer-container"}>
                                <div style={{textAlign: 'center', width: '100%'}}>
                                    <div style={{padding: '4px'}}><b>Apply to Lifts running</b></div>
                                    {(!editIsHeatbeat) && <div>{editTrigger.firmware_target || "- no description -"}</div>}
                                    {editIsHeatbeat && <div>* All firmware revisions *</div>}
                                </div>
                            </div>

                            <div style={{marginLeft: '10px', marginRight: '10px', textAlign: 'center'}}>
                                <div className={"fw-text"}>Over Period</div>
                                <span className="material-icons" style={{fontSize: '64px'}}>arrow_right_alt</span>
                            </div>

                            <div key={"e_tm1"} className={"timer-container"}>
                                <div style={{width: '100%'}}>
                                    <div style={{display: 'inline-block'}}>
                                        <div style={{padding: '2px'}}><b>Timer</b></div>
                                        <span className="material-icons" style={{fontSize: '56px', color: `${editTrigger.timer ? "green" : "black"}`}} onClick={setTimer}>{editTrigger.timer ? "timer" : "timer_off"}</span>
                                    </div>
                                    <div style={{display: 'inline-block', verticalAlign: 'top', marginTop: '20px'}}>
                                        {editTrigger.timer &&
                                            <div style={{display: 'inline-block'}}>
                                                <input type={"number"} style={{width: '50px'}} value={editTrigger.timer.value || ""} onChange={(e) => setTimerValue(e.target.value)}/>
                                                <select style={{marginLeft: '10px', marginRight: '10px', marginTop: '5px'}} value={editTrigger.timer.unit || ""} onChange={(e) => setTimerUnit(e.target.value)}>
                                                    <option key={'t_seconds'} value={'seconds'}>second(s)</option>
                                                    <option key={'t_minutes'} value={'minutes'}>minute(s)</option>
                                                    <option key={'t_hours'} value={'hours'}>hour(s)</option>
                                                    <option key={'t_days'} value={'days'}>day(s)</option>
                                                </select>
                                            </div>
                                        }
                                        {(!editTrigger.timer) && <div className={"fw-tm-cell"} style={{marginRight: '10px'}}>immediate</div>}
                                    </div>
                                </div>
                            </div>

                            <div style={{marginLeft: '10px', marginRight: '10px', textAlign: 'center'}}>
                                <div className={"fw-text"}>Notifying</div>
                                <span className="material-icons" style={{verticalAlign: '-7px', fontSize: '64px'}}>arrow_right_alt</span>
                            </div>

                            <div key={"e_tm2"} className={"timer-container"}>
                                <div style={{width: '100%'}}>
                                    <div style={{display: 'inline-block'}}>
                                        <div style={{padding: '2px'}}><b>Notify</b></div>
                                        <span className="material-icons" style={{fontSize: '56px', color: 'black', margin: '5px'}} onClick={() => {
                                        }}>contact_mail</span>
                                    </div>
                                    <div style={{display: 'inline-block', verticalAlign: 'top', marginTop: '20px'}}>
                                        {(!editTrigger.contacts) && <div className={"fw-tm-cell"} style={{marginRight: '10px'}}>No Notification Contacts</div>}
                                        <div style={{paddingTop: '10px'}}>
                                            <span className="material-icons" style={{color: 'green', fontSize: '32px', cursor: 'pointer', marginRight: '50px'}} onClick={() => {
                                            }}>person</span>
                                            <span className="material-icons" style={{color: 'green', fontSize: '32px', cursor: 'pointer'}} onClick={() => {
                                            }}>people</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    {(!editIsHeatbeat) &&
                        <div style={{padding: '5px'}}>
                            <div className="item-container-list">
                                {editTrigger.raw_structure.map((item, idx) => {
                                    switch (item.type) {
                                        case "LEFT_PAREN":
                                            return (
                                                <div key={idx} className={"fw-item-bracket"} style={{marginLeft: '15px', paddingTop: '20px'}} onClick={() => {
                                                }}>
                                                    <div>
                                                        <div className={"LEFT" === item.applied ? "fw-item-bracket-lbl fw-item-bracket-sel" : "fw-item-bracket-lbl fw-item-bracket-nosel"} onClick={() => chooseParen(idx, "LEFT")}><b>(</b></div>
                                                    </div>
                                                </div>
                                            )
                                        case "RIGHT_PAREN":
                                            return (
                                                <div key={idx} className={"fw-item-bracket"} style={{marginLeft: '15px', paddingTop: '20px'}} onClick={() => {
                                                }}>
                                                    <div>
                                                        <div className={"RIGHT" === item.applied ? "fw-item-bracket-lbl fw-item-bracket-sel" : "fw-item-bracket-lbl fw-item-bracket-nosel"} onClick={() => chooseParen(idx, "RIGHT")}><b>)</b></div>
                                                    </div>
                                                </div>
                                            )
                                        case "BOTH_PAREN":
                                            return (
                                                <div key={idx} className={"fw-item-bracket"} style={{marginLeft: '15px'}} onClick={() => {
                                                }}>
                                                    <div>
                                                        <div className={"LEFT" === item.applied ? "fw-item-bracket-lbl fw-item-bracket-sel" : "fw-item-bracket-lbl fw-item-bracket-nosel"} onClick={() => chooseParen(idx, "LEFT")}><b>(</b></div>
                                                        <div className={"RIGHT" === item.applied ? "fw-item-bracket-lbl fw-item-bracket-sel" : "fw-item-bracket-lbl fw-item-bracket-nosel"} onClick={() => chooseParen(idx, "RIGHT")}><b>)</b></div>
                                                    </div>
                                                </div>
                                            )
                                        case "JOIN":
                                            return (
                                                <div key={idx} className={"fw-item-join"} style={{marginLeft: '15px', paddingTop: '5px'}} onClick={() => {
                                                }}>
                                                    <div>
                                                        <div className={"AND" === item.applied ? "fw-item-join-lbl fw-item-join-sel" : "fw-item-join-lbl fw-item-join-nosel"}
                                                             style={{padding: '4px'}} onClick={() => chooseJoin(idx, "AND")}><b>AND</b></div>
                                                        <div className={"OR" === item.applied ? "fw-item-join-lbl fw-item-join-sel" : "fw-item-join-lbl fw-item-join-nosel"}
                                                             style={{padding: '4px'}} onClick={() => chooseJoin(idx, "OR")}><b>OR</b></div>
                                                    </div>
                                                </div>
                                            )
                                        case "ADC":
                                            let adc = item.target;
                                            let value = item.value;
                                            let st = value ? "fw-item-editing" : "fw-item-editing-inv";
                                            let icon = value ? "verified" : "warning";
                                            let iconc = value ? "green" : "orange";
                                            return (
                                                <div style={{marginLeft: '15px'}}>
                                                    <div key={idx} className={st} onClick={() => {
                                                    }}>
                                                        <div>
                                                            <div style={{display: 'inline-block'}}>
                                                                <div className={"fw-item-title"}>{adc.desc || "- no description -"}</div>

                                                                <div>
                                                                    <div style={{display: 'inline-block'}}>
                                                                        <span className="material-icons" style={{color: `${iconc}`, fontSize: '32px'}}>{icon}</span>
                                                                    </div>
                                                                    <div style={{display: 'inline-block'}}>
                                                                        <div className={"HIGH" === value ? "fw-hl-cell-sel" : "fw-hl-cell"} onClick={() => setEditValue(idx, "HIGH")}>
                                                                            <div style={{display: 'inline-block'}}>{adc.hl || "- n/a -"}</div>
                                                                        </div>
                                                                        <div className={"LOW" === value ? "fw-hl-cell-sel" : "fw-hl-cell"} onClick={() => setEditValue(idx, "LOW")}>
                                                                            <div style={{display: 'inline-block'}}>{adc.ll || "- n/a -"}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div style={{paddingTop: '10px'}}>
                                                                <span className="material-icons" style={{color: 'red', fontSize: '32px', cursor: 'pointer'}} onClick={() => removeTriggerItem(idx, "ADC")}>delete</span>
                                                                <div className={"fw-item-bit"} style={{display: 'inline-block', float: 'right'}}>
                                                                    <div style={{fontSize: '12px'}}>{adc.adc} bit {adc.bit}</div>
                                                                    <div className={"fw-item-desc"}>{adc.io_id}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )

                                        case "ANALOGUE" :
                                            return analogueEditor(idx, item);

                                    }

                                })}
                            </div>
                        </div>
                    }

                </div>
            }


            {(selectedFW) &&
                <>
                    <div className="section-header">Available Data Dictionary</div>
                    <div style={{padding: '10px'}}>
                        <div style={{width: '300px', display: 'inline-block', marginRight: '50px'}}>
                            <BasicFilterInput onFilter={handleFilter}/>
                        </div>
                        <div style={{marginLeft: '20px', marginTop: '10px', display: 'inline-block'}} onClick={() => setDisplayGroups(!displayGroups)}>
                            <ButtonGlyph glyph={displayGroups ? "check_box" : "check_box_outline_blank"}
                                         cn="material-symbols-rounded"
                                         style={{fontSize: '24px', verticalAlign: 'middle', color: 'darkgray', width: '40px'}}/>
                            <small><i>Group Digital IO</i></small>
                        </div>
                        <div style={{marginLeft: '20px', marginTop: '10px', display: 'inline-block'}} onClick={() => setDisplayAdcItems(!displayAdcItems)}>
                            <ButtonGlyph glyph={displayAdcItems ? "check_box" : "check_box_outline_blank"}
                                         cn="material-symbols-rounded"
                                         style={{fontSize: '24px', verticalAlign: 'middle', color: 'darkgray', width: '40px'}}/>
                            <small><i>Show ADC Items</i></small>
                        </div>
                        <div style={{marginLeft: '20px', marginTop: '10px', display: 'inline-block'}} onClick={() => setDisplayAnalogueItems(!displayAnalogueItems)}>
                            <ButtonGlyph glyph={displayAnalogueItems ? "check_box" : "check_box_outline_blank"}
                                         cn="material-symbols-rounded"
                                         style={{fontSize: '24px', verticalAlign: 'middle', color: 'darkgray', width: '40px'}}/>
                            <small><i>Show Analogue Items</i></small>
                        </div>
                    </div>

                    {(displayAdcItems && !displayGroups) &&
                        <div className="item-container-list">
                            <div>
                                <h2>Digital On/Off Data Elements</h2>
                                <div className="item-container-list">
                                    <div style={{padding: '5px'}}>
                                        <div className="section-header">ADC A Items</div>
                                        {adcAItems.map((item, idx) => {
                                            if (!item.io_id.toLowerCase().includes(filterText) && !item.desc.toLowerCase().includes(filterText)) {
                                                return null;
                                            }
                                            return (
                                                <div key={idx} className={"fw-item"} onClick={() => addEditItem(item, "ADC")}>
                                                    <div>
                                                        <div className={"fw-item-bit"} style={{display: 'inline-block'}}>
                                                            <div style={{fontSize: '9px'}}>bit</div>
                                                            {item.bit}</div>
                                                        <div style={{display: 'inline-block'}}>
                                                            <div className={"fw-item-title"}>{item.io_id}</div>
                                                            <small>
                                                                {item.desc || "- no description -"}<br/>
                                                            </small>
                                                            <div>
                                                                <div>
                                                                    <div className={"fw-level-cell"} style={{width: '30px'}}>HIGH</div>
                                                                    <div className={"fw-level-cell"}>=> {item.hl || "- n/a -"}</div>
                                                                </div>
                                                                <div>
                                                                    <div className={"fw-level-cell"} style={{width: '30px'}}>LOW</div>
                                                                    <div className={"fw-level-cell"}>=> {item.ll || "- n/a -"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                    <div style={{padding: '5px'}}>
                                        <div className="section-header">ADC B Items</div>
                                        {adcBItems.map((item, idx) => {
                                            if (!item.io_id.toLowerCase().includes(filterText) && !item.desc.toLowerCase().includes(filterText)) {
                                                return null;
                                            }
                                            return (
                                                <div key={idx} className={"fw-item"} onClick={() => addEditItem(item, "ADC")}>
                                                    <div>
                                                        <div className={"fw-item-bit"} style={{display: 'inline-block'}}>
                                                            <div style={{fontSize: '9px'}}>bit</div>
                                                            {item.bit}</div>
                                                        <div style={{display: 'inline-block'}}>
                                                            <div className={"fw-item-title"}>
                                                                {item.io_id}</div>
                                                            <small>
                                                                {item.desc || "- no description -"}<br/>
                                                            </small>
                                                            <div>
                                                                <div>
                                                                    <div className={"fw-level-cell"} style={{width: '30px'}}>HIGH</div>
                                                                    <div className={"fw-level-cell"}>=> {item.hl || "- n/a -"}</div>
                                                                </div>
                                                                <div>
                                                                    <div className={"fw-level-cell"} style={{width: '30px'}}>LOW</div>
                                                                    <div className={"fw-level-cell"}>=> {item.ll || "- n/a -"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                    <div style={{padding: '5px'}}>
                                        <div className="section-header">ADC C Items</div>
                                        {adcCItems.map((item, idx) => {
                                            if (!item.io_id.toLowerCase().includes(filterText) && !item.desc.toLowerCase().includes(filterText)) {
                                                return null;
                                            }
                                            return (
                                                <div key={idx} className={"fw-item"} onClick={() => addEditItem(item, "ADC")}>
                                                    <div>
                                                        <div className={"fw-item-bit"} style={{display: 'inline-block'}}>
                                                            <div style={{fontSize: '9px'}}>bit</div>
                                                            {item.bit - 1}
                                                        </div>
                                                        <div style={{display: 'inline-block'}}>
                                                            <div className={"fw-item-title"}>{item.io_id}</div>
                                                            <small>
                                                                {item.desc || "- no description -"}<br/>
                                                            </small>
                                                            <div>
                                                                <div>
                                                                    <div className={"fw-level-cell"} style={{width: '30px'}}>HIGH</div>
                                                                    <div className={"fw-level-cell"}>=> {item.hl || "- n/a -"}</div>
                                                                </div>
                                                                <div>
                                                                    <div className={"fw-level-cell"} style={{width: '30px'}}>LOW</div>
                                                                    <div className={"fw-level-cell"}>=> {item.ll || "- n/a -"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                    <div style={{padding: '5px'}}>
                                        <div className="section-header">Outputs</div>
                                        {adcOutItems.map((item, idx) => {
                                            if (!item.io_id.toLowerCase().includes(filterText) && !item.desc.toLowerCase().includes(filterText)) {
                                                return null;
                                            }
                                            return (
                                                <div key={idx} className={"fw-item"} onClick={() => addEditItem(item, "ADC")}>
                                                    <div>
                                                        <div className={"fw-item-bit"} style={{display: 'inline-block'}}>
                                                            <div style={{fontSize: '9px'}}>bit</div>
                                                            {item.bit - 1}
                                                        </div>
                                                        <div style={{display: 'inline-block'}}>
                                                            <div className={"fw-item-title"}>{item.io_id}</div>
                                                            <small>
                                                                {item.desc || "- no description -"}<br/>
                                                            </small>
                                                            <div>
                                                                <div>
                                                                    <div className={"fw-level-cell"} style={{width: '30px'}}>HIGH</div>
                                                                    <div className={"fw-level-cell"}>=> {item.hl || "- n/a -"}</div>
                                                                </div>
                                                                <div>
                                                                    <div className={"fw-level-cell"} style={{width: '30px'}}>LOW</div>
                                                                    <div className={"fw-level-cell"}>=> {item.ll || "- n/a -"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    }


                    {displayAdcItems && displayGroups &&
                        <div className="item-container-list">
                            <div>
                                <h4>Groups</h4>
                                <div className="item-container-list">
                                    {selectedFW.groups.map((group, idx) => {
                                        return (
                                            <div style={{padding: '5px'}}>
                                                <div className="section-header">{group.name} - {group.desc}</div>
                                                {group.io.map((io, idx) => {
                                                    let item = lookupAdcItemForSelectedFw(io);
                                                    if (!item) {
                                                        return (
                                                            <div key={idx}>
                                                                <p><b>{io} - Definition not found</b></p>
                                                            </div>
                                                        )
                                                    }

                                                    if (!item.io_id.toLowerCase().includes(filterText) && !item.desc.toLowerCase().includes(filterText)) {
                                                        return null;
                                                    }

                                                    return (
                                                        <div key={idx} className={"fw-item"} onClick={() => addEditItem(item, "ADC")}>
                                                            <div>
                                                                <div className={"fw-item-bit"} style={{display: 'inline-block'}}>
                                                                    <div style={{fontSize: '9px'}}>bit</div>
                                                                    {item.bit - 1}
                                                                </div>
                                                                <div style={{display: 'inline-block'}}>
                                                                    <div className={"fw-item-title"}>{item.desc || "- no description -"}</div>
                                                                    <small>
                                                                        {item.io_id}<br/>
                                                                    </small>
                                                                    <div>
                                                                        <div>
                                                                            <div className={"fw-level-cell"} style={{width: '30px'}}>HIGH</div>
                                                                            <div className={"fw-level-cell"}>=> {item.hl || "- n/a -"}</div>
                                                                        </div>
                                                                        <div>
                                                                            <div className={"fw-level-cell"} style={{width: '30px'}}>LOW</div>
                                                                            <div className={"fw-level-cell"}>=> {item.ll || "- n/a -"}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                                })}
                                            </div>

                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {(displayAnalogueItems) &&
                        <div>
                            <h2>Analogue Data Items</h2>
                            <div className="item-container-list" style={{padding: '5px'}}>
                                {analogueItems.map((item, idx) => {
                                    if (!item.label.toLowerCase().includes(filterText)) {
                                        return null;
                                    }

                                    return (
                                        <div style={{padding: '5px'}}>
                                            <div key={idx} style={{padding: '10px'}} className={"fw-item"} onClick={() => addEditItem(item, "ANALOGUE")}>
                                                <div className={"fw-item-title"}>{item.label} {item.is_lookup ? `[lookup]` : ``}</div>
                                                <div>Key: [{item.key}]</div>
                                                {(item.is_lookup) &&
                                                    <ul className={"lookup-list"} style={{marginLeft: '20px', paddingTop: '10px'}}>
                                                        {Object.keys(item.lookup).map((k, idx) => {
                                                            let lu = item.lookup[k];
                                                            return (
                                                                <li key={idx}><b>[{k}] - </b>{lu || "- no description -"}</li>
                                                            )
                                                        })
                                                        }

                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    }

                </>
            }

        </div>
    )
}

export default TriggerEditor;