

const reduce = (node) => {
    if (!node) {
        return null;
    }

    if (node.type === 'TERM') {
        // Simplest case, just return the term.
        return node ;
    }

    if (node.left && node.right) {
        // Simplify left and right to create a new expression.
        let l = reduce(node.left);
        let r = reduce(node.right);

        if (l && r) {
            return {
                type: 'EXPR',
                left: l,
                op: node.op,
                right: r
            }
        }

        return l
    }

    if (node.left) {
        return reduce(node.left);
    }

    return null
}

const triggerRuleParser = (rules, context) => {
    for (let i = 0 ; i < rules.length; i++) {
        let rule = rules[i] ;

        console.log(`Expr Stack: [${i}] -> `, context.expr_stack);
        switch (rule.type) {
            case 'LEFT_PAREN':
                if (!rule.applied) {
                    continue ;
                }
                // console.log("[Processing PAREN] (left)");
                context.expr_stack.push({
                    type : 'EXPR',
                    left: null,
                    op : null,
                    right: null
                });
                break ;
            case 'RIGHT_PAREN':
                if (!rule.applied) {
                    continue ;
                }
                // console.log("[Processing PAREN] (right)");
                let rh = reduce(context.expr_stack.pop());
                if (rh) {
                    let rh2 = context.expr_stack.pop();
                    if (!rh2) {
                        context.expr_stack.push(rh);
                    }
                    else {
                        // We could have a term or an expr here.
                        if (rh2.type === 'EXPR') {
                            rh2.right = rh ;
                            context.expr_stack.push(rh2);
                        }
                        else if (rh2.type === 'TERM') {
                            context.expr_stack.push({
                                type : 'EXPR',
                                left: rh2,
                                op : rh2.op,
                                right: rh
                            });
                        }
                    }
                }
                break ;
            case 'BOTH_PAREN':
                if (!rule.applied) {
                    continue ;
                }
                // console.log(`[Processing PAREN] (${rule.applied})`);

                if (rule.applied === 'LEFT') {
                    context.expr_stack.push({
                        type : 'EXPR',
                        left: null,
                        op : null,
                        right: null
                    });
                }
                else if (rule.applied === 'RIGHT') {
                    let rh = reduce(context.expr_stack.pop());
                    if (rh) {
                        let rh2 = context.expr_stack.pop();
                        if (!rh2) {
                            context.expr_stack.push(rh);
                        }
                        else {
                            // We could have a term or an expr here.
                            if (rh2.type === 'EXPR') {
                                rh2.right = rh ;
                                context.expr_stack.push(rh2);
                            }
                            else if (rh2.type === 'TERM') {
                                context.expr_stack.push({
                                    type : 'EXPR',
                                    left: rh2,
                                    op : rh2.op,
                                    right: rh
                                });
                            }
                        }
                    }
                }

                break ;
            case 'ADC':
            case "ANALOGUE":
                // console.log("[Processing ADC] ", rule);
                let e1 = context.expr_stack.pop();
                if (!e1) {
                    context.expr_stack.push({
                        type : 'EXPR',
                        left: {type : 'TERM', value : rule.value, target: rule.target, op : rule.operator},
                        op : null,
                        right: null
                    });
                }
                else if (!e1.left) {
                    e1.left = {type : 'TERM', value : rule.value, target: rule.target};
                    context.expr_stack.push(e1);
                }
                else if (!e1.right) {
                    e1.right = {type: 'TERM', value: rule.value, target: rule.target};
                    context.expr_stack.push(e1);
                }

                break ;
            case 'JOIN':
                // console.log("[Processing JOIN] ", rule.applied);
                let j1 = context.expr_stack.pop();
                if (!j1) {
                    continue ; // should not happen
                }

                // we have either a term or an expression here.
                if (j1.type === 'TERM') {
                    // For a term, we need to create a new expression
                    context.expr_stack.push({
                        type : 'EXPR',
                        left: j1,
                        op : rule.applied,
                        right: null
                    });
                }
                else {
                    // We may have a full expression already so need to create a new one.
                    if (j1.left && j1.op && j1.right) {
                        context.expr_stack.push({
                            type : 'EXPR',
                            left: j1,
                            op : rule.applied,
                            right: null
                        });
                    }
                    else if (j1.left) {
                        j1.op = rule.applied;
                        context.expr_stack.push(j1);
                    }
                }

                break ;
        }
    }
}

export default triggerRuleParser ;


