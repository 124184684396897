import React, {useContext} from "react";
import {AuthContext} from "../../ResponsiveApp";


const TriggerNode = ({trigger, onShowDetail, allowSelect = true}) => {
    const {authorisation} = useContext(AuthContext)

    let errc = trigger.is_valid ? "" : "trigger-container-err"
    return (
        <div className={`trigger-container ${errc}`} style={{height: 'fit-content'}} onClick={() => allowSelect ? onShowDetail(trigger) : {}}>
            <div style={{textAlign: 'center', width: '100%'}}>
                <div style={{padding: '4px'}}><b>{trigger.trigger_name}</b></div>
                <div style={{padding: '10px', minWidth: '260px', textAlign: 'left', fontStyle: 'italic'}}>Firmware: {trigger.firmware_target || "n/a"}</div>
                {trigger.timer &&
                    <div style={{padding: '10px', minWidth: '260px', textAlign: 'left', fontStyle: 'italic'}}>Monitoring Period: {trigger.timer.value} {trigger.timer.unit}</div>
                }
                <div style={{padding: '10px', maxWidth: '260px', textAlign: 'left', color: 'darkblue'}}>{trigger.trigger_desc || "- no description -"}</div>
            </div>
            <div className={"bottom-div"} style={{padding: '2px', textAlign: 'left'}}>
                <div>
                    <div style={{padding: '10px', textAlign: 'left', color: 'black'}}>Activation Count {trigger.fire_count}</div>
                </div>
                <span className="material-symbols-outlined" title={`${trigger.is_valid ? 'Trigger is Valid' : 'Invalid Definition'}`} style={{fontSize: '36px', verticalAlign: '-10px', marginRight: '5px', color: `${trigger.is_valid ? 'green' : 'red'}`}}>{trigger.is_valid ? 'verified' : 'dangerous'}</span>
                <span className="material-symbols-outlined" title={`${trigger.is_active ? 'Trigger is Active' : 'Trigger Disabled'}`} style={{fontSize: '36px', verticalAlign: '-10px', marginRight: '5px', color: `${trigger.is_active ? 'green' : 'orange'}`}}>run_circle</span>
                <span className="material-symbols-outlined" title={`${trigger.timer ? 'Monitoring period defined' : 'Checked on all data received'}`} style={{fontSize: '36px', verticalAlign: '-10px', marginRight: '5px', color: `${trigger.timer ? 'green' : 'darkgray'}`}}>{trigger.timer ? 'timer' : 'timer_off'}</span>
                <span className="material-symbols-outlined" title={`${trigger.should_notify ? 'Notifications enabled' : 'Notifications disabled'}`}
                      style={{fontSize: '36px', verticalAlign: '-10px', marginRight: '5px', color: `${trigger.should_notify ? 'green' : 'orange'}`}}>{trigger.should_notify ? 'notifications_active' : 'notifications_off'}</span>
                <span className="material-symbols-outlined" title={`${trigger.is_locked ? 'Trigger definition locked' : 'Trigger definition unlocked'}`} style={{fontSize: '36px', verticalAlign: '-10px', marginRight: '5px', color: `${trigger.is_locked ? 'green' : 'orange'}`}}>{trigger.is_locked ? 'lock' : 'lock_open'}</span>
                <span className="material-symbols-outlined" title={`${(trigger.notify_list && (trigger.notify_list.users.length > 0 || trigger.notify_list.groups.length > 0)) ? 'Notification contacts defined' : 'No Notification contacts defined'}`}
                      style={{fontSize: '36px', verticalAlign: '-10px', marginRight: '30px', color: `${(trigger.notify_list && (trigger.notify_list.users.length > 0 || trigger.notify_list.groups.length > 0)) ? 'green' : 'orange'}`}}>people</span>


            </div>
        </div>
    )
}

export default TriggerNode;