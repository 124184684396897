import React, {useEffect, useRef, useState} from "react";


export const BarcodeScanner = ({supported_formats, onScanAvailable, onSupported, onScanError, onCameraPermission}) => {

    const [scannerSupported, setScannerSupported] = useState(false);
    const [detector, setDetector] = useState(null);
    const [scanning, setScanning] = useState(false);
    const [scanTimer, setScanTimer] = useState(null);
    // const videoEl = useRef(null);
    const [code, setCode] = useState(null);
    const [cameraPermission, setCameraPermission] = useState(null);
    const [cameraAvailable, setCameraAvailable] = useState(false);

    useEffect(() => {
        // check compatibility
        if (!("BarcodeDetector" in window)) {
            console.log("Barcode Detector is not supported by this browser.");
            setScannerSupported(false);
            onSupported(false);
        } else {
            console.log("Barcode Detector supported!");
            setScannerSupported(true);
            onSupported(true);

            // create new detector
            let barcodeDetector = new window.BarcodeDetector({
                formats: supported_formats,
            });

            setDetector(barcodeDetector);

            // check supported types
            // window.BarcodeDetector.getSupportedFormats().then((supportedFormats) => {
            //     supportedFormats.forEach((format) => console.log(format));
            // });

            navigator.permissions.query({name: 'camera'})
                .then((permissionObj) => {
                    console.log("Camera Permission ::->", permissionObj.state);
                    onCameraPermission(permissionObj.state);
                    setCameraPermission(permissionObj.state);
                    setCameraAvailable(permissionObj.state === 'granted');
                })
                .catch((error) => {
                    console.log('Camera Permission ::-> Got error :', error);
                    onCameraPermission('error');
                    setCameraPermission('error');
                    setCameraAvailable(false);
                })
        }

    },[])

    const startScan = async () => {
        try {
            setScanning(true);

            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: {
                        ideal: "environment"
                    }
                },
                audio: false
            });

            const videoEl = document.getElementById("scanvideo");
            videoEl.srcObject = stream;
            await videoEl.play();

            let sct = setInterval(async () => {
                const barcodes = await detector.detect(videoEl);
                if (barcodes.length <= 0)
                    return;
                console.log(barcodes.map(barcode => barcode.rawValue));

                setCode(barcodes[0].rawValue);
            }, 1000)

            setScanTimer(sct);
        }
        catch(e) {
            console.error(e);
            onScanError(e);
            setScanning(false);
        }
    }

    const stopScan = () => {
        setScanning(false);
        setCode(null);
        clearInterval(scanTimer);
    }

    return (
        <div>
            <div>
               <span className="material-symbols-outlined" style={{
                   marginRight: '4px',
                   color: `${"granted" === cameraPermission ? "green" : "red"}`,
                   fontSize: '64pt'
               }}>{"granted" === cameraPermission ? "photo_camera" : "no_photography"}</span>

                <span className="material-symbols-outlined" style={{
                    marginRight: '4px',
                    color: `${scannerSupported ? "green" : "red"}`,
                    fontSize: '64pt'
                }}>qr_code_scanner</span>
            </div>

            {cameraAvailable &&
                <>
                    <h3>Access to camera is available</h3>
                    {scannerSupported && <h3>Code scanner is supported</h3>}
                    {!scannerSupported &&
                        <>
                            <h3>Code scanner is not supported</h3>
                            <p>Camera access is allowed but the browser is unable to detect barcodes.<br/><br/>Enter code details manually via the keyboard</p>
                        </>
                    }
                </>
            }
            {!cameraAvailable &&
                <>
                    <h3>Camera access is not available - scanning is not possible</h3>
                    <p>Adjust browser permissions to allow camera access or enter details manually via the keyboard</p>
                </>
            }


            {(cameraAvailable && scannerSupported && detector) &&
                <div>
                    <h4>QR Detection is ready</h4>
                    {(!scanning) &&
                        <button className="button-search" onClick={startScan}>
                                        <span className="material-icons" style={{
                                            verticalAlign: '-4px',
                                            paddingRight: '8px',
                                            fontSize: '18px'
                                        }}>qr_code</span>Start Scan
                        </button>
                    }

                    {(scanning) &&
                        <>
                            <div>
                                <button className="button-search" onClick={stopScan}>
                                    <span className="material-icons" style={{
                                        verticalAlign: '-4px',
                                        paddingRight: '8px',
                                        fontSize: '18px'
                                    }}>qr_code</span>Stop
                                </button>
                                <div>
                                    {code && <div>Code: {code}</div>}
                                    {!code && <div>Scanning...</div>}
                                </div>
                            </div>
                        </>
                    }

                    <div style={{padding: '20px', backgroundColor: 'lightgray', width: 'fit-content'}}>
                        <video id={"scanvideo"} style={{width: '300px', height: '300px'}}/>
                    </div>
                </div>

            }
        </div>
    )
}