// These values map directly to the io_adc_item_name table in the database

import Helper from "../library/Helper";

export const IOItemTypes = {
    BOT_LIM1_BIT : {"key" : "1", "label" : "BOT_LIM1_BIT"} ,
    ROL2_BIT : {"key" : "2", "label" : "ROL2_BIT"} ,
    OVRL_BIT : {"key" : "3", "label" : "OVRL_BIT"} ,
    PRLY_PWR_DET_BIT : {"key" : "4", "label" : "PRLY_PWR_DET_BIT"} ,
    BOT_LIM2_BIT : {"key" : "5", "label" : "BOT_LIM2_BIT"} ,
    DOOR_DSS_BIT : {"key" : "6", "label" : "DOOR_DSS_BIT"} ,
    BALANCE1_BIT : {"key" : "7", "label" : "BALANCE1_BIT"} ,
    DRLY_PWR_DET_BIT : {"key" : "8", "label" : "DRLY_PWR_DET_BIT"} ,
    BOT_LIM3_BIT : {"key" : "9", "label" : "BOT_LIM3_BIT"} ,
    LIGHT_GUARD_FRONT_BIT : {"key" : "10", "label" : "LIGHT_GUARD_FRONT_BIT"} ,
    TOP_OBST1_BIT : {"key" : "11", "label" : "TOP_OBST1_BIT"} ,
    URLY_PWR_DET_BIT : {"key" : "12", "label" : "URLY_PWR_DET_BIT"} ,
    BOT_FLIM_BIT : {"key" : "13", "label" : "BOT_FLIM_BIT"} ,
    SPARE2_BIT : {"key" : "14", "label" : "SPARE2_BIT"} ,
    ROL1_BIT : {"key" : "15", "label" : "ROL1_BIT"} ,
    TOG_DOWN_BIT : {"key" : "16", "label" : "TOG_DOWN_BIT"} ,
    KEYSWITCH_BIT : {"key" : "17", "label" : "KEYSWITCH_BIT"} ,
    DOOR_LSS_BIT : {"key" : "18", "label" : "DOOR_LSS_BIT"} ,
    LIGHT_GUARD_REAR_BIT : {"key" : "19", "label" : "LIGHT_GUARD_REAR_BIT"} ,
    TOP_OBST2_BIT : {"key" : "20", "label" : "TOP_OBST2_BIT"} ,
    DOWN_CAB_BIT : {"key" : "21", "label" : "DOWN_CAB_BIT"} ,
    CHARGER_CONNECTED_BIT : {"key" : "22", "label" : "CHARGER_CONNECTED_BIT"} ,
    TOP_FLIM_BIT : {"key" : "23", "label" : "TOP_FLIM_BIT"} ,
    TOG_UP_BIT : {"key" : "24", "label" : "TOG_UP_BIT"} ,
    UP_CAB_BIT : {"key" : "25", "label" : "UP_CAB_BIT"} ,
    SAFETY_BIT : {"key" : "26", "label" : "SAFETY_BIT"} ,
    SPARE1_BIT : {"key" : "27", "label" : "SPARE1_BIT"} ,
    TOP_OBST3_BIT : {"key" : "28", "label" : "TOP_OBST3_BIT"} ,
    MID_CAB_BIT : {"key" : "29", "label" : "MID_CAB_BIT"} ,
    DOOR_HZ_BIT : {"key" : "30", "label" : "DOOR_HZ_BIT"} ,
    BALANCE2_BIT : {"key" : "31", "label" : "BALANCE2_BIT"} ,
    TOP_OBST4_BIT : {"key" : "32", "label" : "TOP_OBST4_BIT"} ,
    BOT_LIM4_BIT : {"key" : "33", "label" : "BOT_LIM4_BIT"} ,
    BOT_LIM5_BIT : {"key" : "34", "label" : "BOT_LIM5_BIT"} ,
    BOT_LIM6_BIT : {"key" : "35", "label" : "BOT_LIM6_BIT"} ,
    BOT_LIM7_BIT : {"key" : "36", "label" : "BOT_LIM7_BIT"} ,
    M1_BIT : {"key" : "37", "label" : "M1_BIT"} ,
    M2_BIT : {"key" : "38", "label" : "M2_BIT"} ,
    M3_BIT : {"key" : "39", "label" : "M3_BIT"} ,
    DIP_SWITCH_1 : {"key" : "40", "label" : "DIP_SWITCH_1"} ,
    DIP_SWITCH_2 : {"key" : "41", "label" : "DIP_SWITCH_2"} ,
    DIP_SWITCH_3 : {"key" : "42", "label" : "DIP_SWITCH_3"} ,
    DIP_SWITCH_4 : {"key" : "43", "label" : "DIP_SWITCH_4"} ,
    DIP_SWITCH_5 : {"key" : "44", "label" : "DIP_SWITCH_5"} ,
    DIP_SWITCH_6 : {"key" : "45", "label" : "DIP_SWITCH_6"} ,
    DIP_SWITCH_7 : {"key" : "46", "label" : "DIP_SWITCH_7"} ,
    DIP_SWITCH_8 : {"key" : "47", "label" : "DIP_SWITCH_8"} ,
    ROL3_BIT : {"key" : "48", "label" : "ROL3_BIT"}
}


const ChargerLookupMap = {
    0 : "Charger Relay off – motor in motion",
    1 : "Charger post move settle",
    2 : "Charger post move initial check",
    3 : "Standard charge procedure with health checks",
    4 : "Charger post move not charging",
    255 : "System Reset"
}

const chargerLookup = (value) => {
    return ChargerLookupMap[value] ? ChargerLookupMap[value] : "Unknown"
}

const nullLookup = (value) => {
    return value
}

const chargerValueMap = (v) => {
    return v === 255 ? -1 : v;
}

const chargerTickMap = () => {
    let r = []
    for (let k in ChargerLookupMap) {
        if (k === "255") {
            r.push({v: -1, f: ChargerLookupMap[k]})
        }
        else {
            r.push({v: parseInt(k), f: ChargerLookupMap[k]})
        }
    }

    return r
}


export const TimelineAnalogueOptions = [
    {value: "Voltage",          selected : false, selected_style: 'tlcl_red', graph_colour: 'red', display: "Battery Voltage", target: 'v_bat_voltage_mv', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup, low_baseline: 0, high_baseline: 30000},
    {value: "LiftAngle",        selected : false, selected_style: 'tlcl_blue', graph_colour: 'blue', display: "Lift Angle", target: 'l_lift_angle', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M1CurrentUp",      selected : false, selected_style: 'tlcl_orange', graph_colour: 'orange', display: "M1 Current UP", target: 'a_m1_current_up', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M2CurrentUp",      selected : false, selected_style: 'tlcl_yellow', graph_colour: 'yellow', display: "M2 Current UP", target: 'a_m2_current_up', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M1CurrentDown",    selected : false, selected_style: 'tlcl_lightgreen', graph_colour: 'lightgreen', display: "M1 Current Down", target: 'q_m1_current_down', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M2CurrentDown",    selected : false, selected_style: 'tlcl_green', graph_colour: 'green', display: "M2 Current Down", target: 'q_m2_current_down', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M1EncCount",       selected : false, selected_style: 'tlcl_purple', graph_colour: 'purple', display: "M1 Encoder Count", target: 'e_motor1_enc_count', min_max_multiplier: 0.0001,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M2EncCount",       selected : false, selected_style: 'tlcl_magenta', graph_colour: 'magenta', display: "M2 Encoder Count", target: 'e_motor2_enc_count', min_max_multiplier: 0.0001,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "CurPotRead",       selected : false, selected_style: 'tlcl_cyan', graph_colour: 'cyan', display: "Current Weight Reading", target: 'k_cur_pot_read', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "ChargerStatus",    selected : false, selected_style: 'tlcl_grey', graph_colour: 'darkgray', display: "Charger Status", target: 'h_charger_status', min_max_multiplier: 0.1, value_map : chargerValueMap, map_vaxis_tick: chargerTickMap, tooltip_map: chargerLookup},
] ;

const get_target_bit_set = (datapoint, source_adc) => {
    switch (source_adc) {
        case "a":
        case "A":
            return datapoint.adc_bits_a
        case "b":
        case "B":
            return datapoint.adc_bits_b
        case "c":
        case "C":
            return datapoint.adc_bits_c
        case "O":
            return datapoint.o_outputs
        default:
            return null
    }
}

export const IODefaultDoorState = () => {
    return {
        valid: false,
        locked: false,
        open: false,
        on_landing: false,
        dss : false,
        hz : false,
        lss : false
    }
}

export const IODoorState = (io_model, datapoint) => {
    let result = IODefaultDoorState() ;
    if (!io_model || !datapoint) {
        return result
    }

    let dss = IOGetRaw(io_model, datapoint, IOItemTypes.DOOR_DSS_BIT.label) ;
    let hz = IOGetRaw(io_model, datapoint, IOItemTypes.DOOR_HZ_BIT.label) ;
    let lss = IOGetRaw(io_model, datapoint, IOItemTypes.DOOR_LSS_BIT.label) ;

    result.on_landing = !lss ;
    result.locked = lss ;
    result.valid = true ;
    result.dss = dss ;
    result.hz = hz ;
    result.lss = lss ;
    // result.open = !dss && !hz && !lss ;
    result.open = !dss ;

    return result ;
}

export const IOGetIfNoPriorChainItem = (io_model, datapoint, io_item) => {
    if (!io_model || !datapoint || !io_item) {
        return false
    }

    // console.log("IOGetIfNoPriorChainItem: io_item = ", io_item)
    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    for (let sc of io_model.primary_safety) {
        // console.log("IOGetIfNoPriorChainItem: check = ", sc === io_item, sc, io_item)
        if (sc === io_item) {
            // The bit we're looking for so just run the normal set value
            let rx = IOGet(io_model, datapoint, io_item);
            // console.log("IOGetIfNoPriorChainItem: Found item in primary safety chain = ", io_item, rx)
            return rx ;
        }

        let vx = IOGet(io_model, datapoint, sc);
        if (vx) {
            // console.log("IOGetIfNoPriorChainItem: Found preceding item in primary safety chain = ", sc, ", io_item = ", io_item)
            return false ;
        }
    }

    return false ;
}

export const IOGet = (io_model, datapoint, io_item) => {
    // console.log("IOGet: io_model = ", io_model, ", datapoint = ", datapoint, ", io_item = ", io_item)

    // make sure we have all the data we need
    if (!io_model || !datapoint || !io_item) {
        return false
    }

    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    // console.log("io_model.item_adc_map = ", io_model.target_io.item_adc_map)
    let adc_set = io_model.item_adc_map[io_item]
    // console.log("ADC Set = ", adc_set, io_item)

    if (!adc_set) {
        return false
    }


    // Now we have the adc_set, we need to find the value of the bit in the datapoint
    let bit_set = get_target_bit_set(datapoint, adc_set.adc)
    // console.log("bit_set = ", bit_set)
    if (!bit_set) {
        return ""
    }

    // Now we have the bit_set, we need to find the value of the bit in the datapoint
    let bit_value = bit_set[adc_set.bit - 1]
    // console.log("bit_value check = ", io_item, bit_value, adc_set.trip, bit_value === adc_set.trip)
    return (bit_value === adc_set.trip)
}

export const IOGetRaw = (io_model, datapoint, io_item) => {
    // console.log("IOGet: io_model = ", io_model, ", datapoint = ", datapoint, ", io_item = ", io_item)

    // make sure we have all the data we need
    if (!io_model || !datapoint || !io_item) {
        return false
    }

    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    // console.log("io_model.item_adc_map = ", io_model.item_adc_map)
    let adc_set = io_model.item_adc_map[io_item]
    // console.log("ADC Set = ", adc_set, io_item)

    if (!adc_set) {
        return false
    }


    // Now we have the adc_set, we need to find the value of the bit in the datapoint
    let bit_set = get_target_bit_set(datapoint, adc_set.adc)
    // console.log("bit_set = ", bit_set)
    if (!bit_set) {
        return false
    }

    // Now we have the bit_set, we need to find the value of the bit in the datapoint
    return bit_set[adc_set.bit - 1] === '1'
}

export const IOGetRawValue = (io_model, datapoint, io_item) => {
    // console.log("IOGet: io_model = ", io_model, ", datapoint = ", datapoint, ", io_item = ", io_item)

    // make sure we have all the data we need
    if (!io_model || !datapoint || !io_item) {
        return "?"
    }

    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    // console.log("io_model.item_adc_map = ", io_model.item_adc_map)
    let adc_set = io_model.item_adc_map[io_item]
    // console.log("ADC Set = ", adc_set, io_item)

    if (!adc_set) {
        return "?"
    }

    // Now we have the adc_set, we need to find the value of the bit in the datapoint
    let bit_set = get_target_bit_set(datapoint, adc_set.adc)
    // console.log("bit_set = ", bit_set)
    if (!bit_set) {
        return "?"
    }

    if (adc_set.adc === "O") {
        let raw = bit_set & (1 << (adc_set.bit - 1))
        return raw ? "1" : "0"
    }

    // Now we have the bit_set, we need to find the value of the bit in the datapoint
    return bit_set[adc_set.bit - 1]
}

export const Safety = {
    shouldCheckDirectionalSafety : (io_model, datapoint) => {
        if (!io_model || !datapoint) {
            return false
        }

        // If on a landing then don't check directional safety elements
        let lss = IOGetRawValue(io_model, datapoint, IOItemTypes.DOOR_LSS_BIT.label);
        return lss === '1'
    }
}


export const IODefaultLiftState = () => {
    return {
        is_hb: false,
        door_state: IODefaultDoorState(),
        occupied: false,
        main_safety: {active: false, item: null},
        up_safety: {active: false, item: null},
        down_safety: {active: false, item: null},
        check_dir_safety: false,
        angle: {ok: true, value: 0.0, left: false, right: false},
        weight: 0,
        person_in_lift: false,
        height: 0.0,
        current_floor: null,
        at_bottom_floor: false,
        at_top_floor: false,
        number_of_floors: 0,
        remote_up: false,
        remote_down: false,
        cop_up: false,
        cop_down: false,
        cop_mid: false,
        overloaded: false,
        key_switch: false,
        emergency_stop: false,
        balance1: false,
        balance2: false,
        charger_status: 0,
        wifi_connect_state: 0,
        battery_voltage: 0.0,

        // Top Limits
        top_flim: false,
        top_obst1: false,
        top_obst2: false,
        top_obst3: false,
        top_obst4: false,

        // Bottom Limits
        bot_flim: false,
        bot_lim1: false,
        bot_lim2: false,
        bot_lim3: false,
        bot_lim4: false,
        bot_lim5: false,
        bot_lim6: false,
        bot_lim7: false,

        // Light guards
        light_guard_front: false,
        light_guard_rear: false,

        rol3_bit: false,
        rol2_bit: false,
        prly_pwr_det_bit: false,
        drly_pwr_det_bit: false,

        adc_mapping_available: false,
        adc_a_map: [],
        adc_b_map: [],
        adc_c_map: [],
        output_map: [],
        groups : [],

        l475_runtime_ms : 0,
        f301_runtime_ms : 0,
        rtc : 0,
        last_correction_ms : 0,

        floors : {
            current_floor : null,
            at_bottom_floor : false,
            at_top_floor : false,
            last_floor : null,
        },

        gate_data : []
    }
}

function get_floor_desc(floor, is_v5 = true) {
    // "Software identified floor number. Calculated from ramp and encoder floor values
    // No floor = 0x55
    // Floor error = 0xFF
    // Bottom floor = 0
    // Top floor = 1
    // Mid floor 1 = 2
    // Mid floor 2 = 3 etc."

    if (is_v5) {
        switch (floor) {
            case 0 :
                return "Bottom Floor";
            case 1 :
                return "Top Floor";
            case 0x55 :
                return "No Floor";
            case 0xff :
                return "Floor Error";
            default :
                return `Mid Floor ${floor || ""}`;
        }
    }
    else {
        switch (floor) {
            case 1 :
                return "Bottom Floor";
            case 3 :
                return "Top Floor";
            default :
                return `Unknown Floor ${floor || ""}`;
        }
    }

}

export const IOGetLiftState = (io_model, datapoint, gate_data, device) => {
    // console.log("IOGetLiftState: io_model = ", io_model, ", datapoint = ", datapoint, ", device = ", device);
    let result = IODefaultLiftState() ;

    // console.log("IO Model = ", io_model)
    if (datapoint && io_model) {
        result.data_version = datapoint.fw_version
        result.device_data_id = datapoint.device_data_id
        result.door_state = IODoorState(io_model, datapoint)
        result.gate_data = gate_data
        result.cop_status = datapoint.o_cop_status

        result.f301_log_desc = io_model.F301Lookup.descriptions[datapoint.s_f301_sr] || "n/a";
        result.l475_log_desc = io_model.L475Lookup.descriptions[datapoint.s_f475_sr] || "n/a";
        result.f301_log_key = io_model.F301Lookup.byId[datapoint.s_f301_sr] || "n/a";
        result.l475_log_key = io_model.L475Lookup.byId[datapoint.s_f475_sr] || "n/a";
        result.f301_log_raw = datapoint.s_f301_sr ;
        result.l475_log_raw = datapoint.s_f475_sr ;

        let angle = datapoint.l_lift_angle.toFixed(4) ;
        if (angle >= 0.6) {
            result.angle = {ok : false, value: angle, left : false, right: true}
        }
        else if (angle <= -0.6) {
            result.angle = {ok : false, value: angle, left : true, right: false}
        }
        else {
            result.angle = {ok : true, value: angle, left : false, right: false}
        }

        result.last_journey = {
            direction : (datapoint.j_m1_enc_count_start < datapoint.j_m1_enc_count_end) ? "up" : "down",
            distance : (datapoint.j_m2_enc_count * 0.0174) / 1000.0,
        }

        result.key_switch = IOGet(io_model, datapoint, IOItemTypes.KEYSWITCH_BIT.label)
        result.emergency_stop = IOGetIfNoPriorChainItem(io_model, datapoint, IOItemTypes.SAFETY_BIT.label)
        result.balance1 = IOGetIfNoPriorChainItem(io_model, datapoint, IOItemTypes.BALANCE1_BIT.label)
        result.balance2 = IOGetIfNoPriorChainItem(io_model, datapoint, IOItemTypes.BALANCE2_BIT.label)

        result.overloaded = IOGet(io_model, datapoint, IOItemTypes.OVRL_BIT.label)
        result.cop_up = IOGet(io_model, datapoint, IOItemTypes.UP_CAB_BIT.label)
        result.cop_down = IOGet(io_model, datapoint, IOItemTypes.DOWN_CAB_BIT.label)
        result.cop_mid = IOGet(io_model, datapoint, IOItemTypes.MID_CAB_BIT.label)
        result.remote_up = IOGet(io_model, datapoint, IOItemTypes.TOG_UP_BIT.label)
        result.remote_down = IOGet(io_model, datapoint, IOItemTypes.TOG_DOWN_BIT.label)

        // console.log("COP Key Indexes - ", IOItemTypes.UP_CAB_BIT, IOItemTypes.DOWN_CAB_BIT, IOItemTypes.MID_CAB_BIT);
        // console.log("COP Buttons up=", result.cop_up, " down=", result.cop_down, " mid=", result.cop_mid);
        let adc = datapoint.adc_bits_a
        // console.log("COP ADC= ", adc[IOItemTypes.UP_CAB_BIT - 1], adc[IOItemTypes.DOWN_CAB_BIT - 1], adc[IOItemTypes.MID_CAB_BIT - 1])

        result.weight = datapoint.k_cur_pot_read
        result.charger_status = datapoint.h_charger_status
        result.charger_error = datapoint.h_charger_error
        result.battery_voltage = datapoint.v_bat_voltage_mv / 1000.0
        result.top_flim = IOGet(io_model, datapoint, IOItemTypes.TOP_FLIM_BIT.label)
        result.top_obst1 = IOGet(io_model, datapoint, IOItemTypes.TOP_OBST1_BIT.label)
        result.top_obst2 = IOGet(io_model, datapoint, IOItemTypes.TOP_OBST2_BIT.label)
        result.top_obst3 = IOGet(io_model, datapoint, IOItemTypes.TOP_OBST3_BIT.label)
        result.top_obst4 = IOGet(io_model, datapoint, IOItemTypes.TOP_OBST4_BIT.label)

        result.bot_flim = IOGet(io_model, datapoint, IOItemTypes.BOT_FLIM_BIT.label)
        result.bot_lim1 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM1_BIT.label)
        result.bot_lim2 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM2_BIT.label)
        result.bot_lim3 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM3_BIT.label)
        result.bot_lim4 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM4_BIT.label)
        result.bot_lim5 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM5_BIT.label)
        result.bot_lim6 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM6_BIT.label)
        result.bot_lim7 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM7_BIT.label)

        result.light_guard_front = IOGetIfNoPriorChainItem(io_model, datapoint, IOItemTypes.LIGHT_GUARD_FRONT_BIT.label)
        result.light_guard_rear = IOGetIfNoPriorChainItem(io_model, datapoint, IOItemTypes.LIGHT_GUARD_REAR_BIT.label)

        result.rol3_bit = IOGet(io_model, datapoint, IOItemTypes.ROL3_BIT.label)
        result.rol2_bit = IOGet(io_model, datapoint, IOItemTypes.ROL2_BIT.label)
        result.prly_pwr_det_bit = IOGet(io_model, datapoint, IOItemTypes.PRLY_PWR_DET_BIT.label)
        result.drly_pwr_det_bit = IOGet(io_model, datapoint, IOItemTypes.DRLY_PWR_DET_BIT.label)
        result.check_dir_safety = result.door_state.lss ;

        result.l475_runtime_ms = datapoint.t_l475_runtime_ms
        result.f301_runtime_ms = datapoint.t_f301_runtime_ms
        result.rtc = datapoint.t_rtc
        result.last_correction_ms = datapoint.t_last_correction_ms

        result.fa_smoke_alarm_active = datapoint.fa_smoke_alarm_active
        result.fa_smoke_alarm_finish_travel = datapoint.fa_smoke_alarm_finish_travel

        result.b_env = datapoint.b_env
        result.b_msg_fmt = datapoint.b_msg_fmt
        result.b_tx_type = datapoint.b_tx_type

        if (datapoint.fw_version < 4) {
            result.is_hb = datapoint.g_hb === 1
            result.data_trigger = datapoint.g_hb
            result.reason = datapoint.g_hb === 1 ? "Heartbeat" : "Data Trigger"

            result.floors = {
                v_current_floor : datapoint.r_current_floor,
                v_last_known_floor : datapoint.r_last_known_floor,
                v_ramp_floor : "",
                v_encoder_floor : "",
                at_bottom_floor : datapoint.r_bottom_floor === 1,
                at_top_floor : datapoint.r_top_floor === 1,
                ramp_floor : "",
                encoder_floor : "",
            }

            if (Helper.isNullOrUndefined(result.floors.v_current_floor)) {
                let bf = datapoint.adc_bits_b[8] === "1" ? 1 : 0
                let tf = datapoint.adc_bits_a[2] === "1" ? 1 : 0

                if (bf) {
                    result.floors.v_current_floor = 1
                }
                else if (tf) {
                    result.floors.v_current_floor = 3
                }
                else {
                    result.floors.v_current_floor = 0x55
                }
            }

            if (Helper.isNullOrUndefined(result.floors.v_last_known_floor)) {
                result.floors.v_last_known_floor = 0x55 ;
            }

            result.floors.current_floor = get_floor_desc(datapoint.r_current_floor, false)
            result.floors.last_known_floor = get_floor_desc(datapoint.r_last_known_floor, true)
        }
        else {
            let top_floor = datapoint.adc_bits_a[1] === "1"
            let bot_floor = datapoint.adc_bits_b[8] === "1"

            result.floors = {
                v_current_floor : datapoint.r_current_floor,
                v_last_known_floor : datapoint.r_last_known_floor,
                v_ramp_floor : datapoint.r_ramp_floor,
                v_encoder_floor : datapoint.r_encoder_floor,
                at_top_floor: top_floor,
                at_bottom_floor: bot_floor,
                current_floor : get_floor_desc(datapoint.r_current_floor, true),
                last_known_floor : get_floor_desc(datapoint.r_last_known_floor, true),
                ramp_floor : get_floor_desc(datapoint.r_ramp_floor, true),
                encoder_floor : get_floor_desc(datapoint.r_encoder_floor, true),
            }

            switch (datapoint.g_hb) {
                case 0 : result.reason = "Reason Not Set" ; break ;
                case 1 : result.reason = "Startup Data" ; break ;
                case 2 : result.reason = "Standard Data" ; break ;
                case 3 : result.reason = "Heartbeat Data" ; break ;
                case 4 : result.reason = "E-Stop Data" ; break ;
                case 5 : result.reason = "Charger Error Data"; break ;
                case 6 : result.reason = "Lift Run Error Data"; break ;
                case 7 : result.reason = "RS485 Error Data" ; break ;
                default : result.reason = "Unknown" ; break ;
            }

            result.is_hb = datapoint.g_hb === 3
            result.data_trigger = datapoint.g_hb
            result.v_chrg_pwr_state = datapoint.v_chrg_pwr_state
            result.aa_eng_override = datapoint.aa_eng_override
            result.aa_cab_override = datapoint.aa_cab_override
            result.ae_estop_flag = datapoint.ae_estop_flag
            result.fa_smoke_alarm_active = datapoint.fa_smoke_alarm_active
            result.fa_smoke_alarm_finish_travel = datapoint.fa_smoke_alarm_finish_travel
            result.lv_hard_stop_flag = datapoint.lv_hard_stop_flag
            result.lv_finish_travel = datapoint.lv_finish_travel
            result.ms_301_motor_running = datapoint.ms_301_motor_running
            result.pd1_fully_open = datapoint.pd1_fully_open
            result.pd1_fully_closed = datapoint.pd1_fully_closed
            result.pd2_fully_open = datapoint.pd2_fully_open
            result.pd2_fully_closed = datapoint.pd2_fully_closed
        }
    }

    if (device) {
        result.wifi_connect_state = device.wifi_connect_state_id
        result.person_in_lift = result.weight > 0 && result.weight >= (device.high_weight * 0.75)
        result.occupied = result.weight > 0 && result.weight >= (device.high_weight * 0.75)
        result.number_of_floors = device.number_of_floors
    }

    // TODO - fill in the rest of the data
    // first start with working out the current floor based on the landing data
    // Need to actual build a map for the adc a,b and c which include whether tripped and the actual item label

    if (io_model && datapoint) {
        result.adc_mapping_available = true

        // Now need to fill in the adc mapping
        for (let a of io_model.adca) {
            let target = io_model.item_adc_map[a]
            if (target) {
                let raw = datapoint.adc_bits_a[target.bit - 1]
                let safety = io_model.primary_safety.includes(a)

                result.adc_a_map.push({
                    io : a,
                    adc : target.adc,
                    label: target.io_id,
                    desc : target.desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    safety: safety,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        for (let a of io_model.adcb) {
            let target = io_model.item_adc_map[a]
            if (target) {
                let raw = datapoint.adc_bits_b[target.bit - 1]
                let safety = io_model.primary_safety.includes(a)

                result.adc_b_map.push({
                    io : a,
                    adc : target.adc,
                    label: target.io_id,
                    desc : target.desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        for (let a of io_model.adcc) {
            let target = io_model.item_adc_map[a]
            if (target) {
                let raw = datapoint.adc_bits_c[target.bit - 1]
                let safety = io_model.primary_safety.includes(a)

                result.adc_c_map.push({
                    io : a,
                    adc : target.adc,
                    label: target.io_id,
                    desc : target.desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    safety: safety,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        for (let a of io_model.outputs) {
            let target = io_model.item_adc_map[a]
            if (target) {
                let raw = (datapoint.o_outputs & (1 << target.bit)) ;

                result.output_map.push({
                    io : a,
                    adc : target.adc,
                    label: target.io_id,
                    desc : target.desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    safety: false,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        // bit 6 of adcc is light timer setting bit 0 and bit 7 of adcc is light timer setting bit 1
        // when bit 0 is 0 and bit 1 is 0 then the light time is 10seconds
        // when bit 0 is 0 and bit 1 is 1 then the light time is 20seconds
        // when bit 0 is 1 and bit 1 is 0 then the light time is 45seconds
        // when bit 0 is 1 and bit 1 is 1 then the light time is 90seconds
        // i need to set a flag on result to indicate the light timer setting
        let bit0 = datapoint.adc_bits_c[5] === '1' ? 0 : 1
        let bit1 = datapoint.adc_bits_c[6] === '1' ? 0 : 1
        let bits = `${bit0}:${bit1}`
        switch (bits) {
            case "0:0":
                result.light_timer = 10
                break
            case "0:1":
                result.light_timer = 20
                break
            case "1:0":
                result.light_timer = 45
                break
            case "1:1":
                result.light_timer = 90
                break
            default:
                result.light_timer = 10
        }

        if (datapoint.fw_version >= 5) {
            let pdbit0 = datapoint.adc_bits_c[0] === '1' ? 0 : 1
            let pdbit1 = datapoint.adc_bits_c[2] === '1' ? 0 : 1
            let pdbit2 = datapoint.adc_bits_c[3] === '1' ? 0 : 1
            let pdbits = `${pdbit0}:${pdbit1}:${pdbit2}`
            switch (pdbits) {
                case "0:0:0": result.power_door_enabled = false ; break ;
                case "0:0:1":
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Front, Top Floor Front" ;
                    break ;
                case "0:1:0":
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Front, Top Floor Thru" ;
                    break ;
                case "0:1:1" :
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Thru, Top Floor Front" ;
                    break ;
                case "1:0:0":
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Thru, Top Floor Rear" ;
                    break ;
                case "1:0:1" :
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Rear, Top Floor Front" ;
                    break ;
                case "1:1:0" :
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Rear, Top Floor Rear" ;
                    break ;
                case "1:1:1" :
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Thru, Top Floor Thru" ;
                    break ;
                default:
                    result.power_door_enabled = false
            }

            let sin1 = datapoint.adc_bits_c[9] === '1'
            let sin2 = datapoint.adc_bits_c[10] === '1'
            let sin3 = datapoint.adc_bits_c[11] === '1'

            result.push_to_run = sin1 || sin2
            result.multi_floor = sin2 || sin3
        }
        else {
            result.power_door_enabled = false
            result.push_to_run = false
            result.multi_floor = false
        }


        for (let g of io_model.groups) {
            let group = {
                name: g.name,
                desc: g.desc,
                items: []
            }

            let flag = false ; // g.name === "Outputs" ;

            for (let item of g.io) {
                let target = io_model.item_adc_map[item]

                if (flag) {
                    console.log("Output Item = ", item, target, datapoint.o_outputs)
                }
                if (target) {
                    let raw = IOGetRawValue(io_model, datapoint, item)
                    let safety = io_model.primary_safety.includes(item)

                    if (flag) {
                        console.log("Output Item 2 = ", raw, safety)
                    }

                    group.items.push({
                        io : item,
                        adc : target.adc,
                        label: target.io_id,
                        desc : target.desc,
                        value : raw,
                        trip: target.trip,
                        tripped: raw === target.trip,
                        bit: target.bit,
                        safety: safety,
                        stxt : (raw === "1") ? target.hl : target.ll,
                        trc : target.trc,
                        sc : target.sc
                    })
                }
            }

            result.groups.push(group)
        }
    }

    // console.log("IO Definition Mapped Result = ", result)
    return result
}
















