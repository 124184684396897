import React, {useContext} from "react";
import {AuthContext} from "../../ResponsiveApp";


const TriggerNotifyEditor = ({ trigger, editing, allUsers, allGroups }) => {
    const {authorisation} = useContext(AuthContext)

    let meInNotifyList = (trigger && trigger.notify_list) ? trigger.notify_list.users.find((user) => user.id === authorisation.account.userid) !== null : false ;

    const findUser = (user_id) => {
        if (allUsers) {
            return allUsers.find((user) => user.userid === user_id) ;
        }
        return null ;
    }

    const findGroup = (group_id) => {
        if (allGroups) {
            return allGroups.find((group) => group.contact_group_id === group_id) ;
        }
        return null ;
    }

    return (
        <div style={{marginTop: '50px'}}>
            <div className="section-header">Notifications</div>

            <h4>Individual Users</h4>
            {trigger.notify_list && trigger.notify_list.users.map((user, uidx) => {
                let u = findUser(user.id);
                if (!u) {
                    return null
                }
                return (
                    <div key={`e_tr_u_${uidx}`} style={{marginLeft: '50px'}}>
                        <div>
                            {(user.ct.includes("email")) && <span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px', fontSize: '32px', display: 'inline-block'}}>email</span>}
                            <div style={{padding: '4px', display: 'inline-block'}}>({`${u.firstname} ${u.surname}`}) <i>{u.emailaddress}</i></div>
                        </div>
                    </div>
                )
            })}

            {(!trigger.notify_list || trigger.notify_list.users.length === 0) &&
                <div>No individual users</div>
            }

            {(editing && !meInNotifyList) &&
                <div style={{marginTop: '20px'}}>
                    <button className={"button button-save"}><span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px'}}>add</span>Add me to Contacts</button>
                </div>
            }

            <br/>
            <h4>Contact Groups</h4>
            {trigger.notify_list && trigger.notify_list.groups.map((group, gidx) => {
                let g = findGroup(group.gid);
                if (!g) {
                    return null
                }
                return (
                    <div key={`e_tr_g_${gidx}`} style={{marginLeft: '50px'}}>
                        <div>
                            {(group.ct.includes("email")) && <span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px', fontSize: '32px', display: 'inline-block'}}>email</span>}
                            <div style={{padding: '4px', display: 'inline-block'}}><i>{g.contact_group_name}</i></div>
                        </div>
                    </div>
                )
            })}

            {(!trigger.notify_list || trigger.notify_list.groups.length === 0) &&
                <div>No contact groups assigned</div>
            }

        </div>
    )
}

export default TriggerNotifyEditor;