import React, {useContext} from "react";
import {AuthContext} from "../../ResponsiveApp";
import TriggerNode from "./TriggerNode";


const TriggerNodeList = ({ triggers, title, subtitle, icon , onShowDetail}) => {

    return (
        <div style={{marginBottom: '30px'}}>
            <div className="section-header"><span className="material-symbols-outlined" style={{fontSize: '32px', verticalAlign: '-10px', marginRight: '10px'}}>{icon}</span>{title} <small>{subtitle}</small></div>
            {triggers &&
                <div className="item-container-list">
                    {triggers.map((trigger, tidx) => <TriggerNode key={`e_tr_${tidx}`} trigger={trigger}  onShowDetail={onShowDetail} />)}
                </div>
            }
            {(!triggers) && <div>No applicable Triggers</div>}
        </div>
    )
}

export default TriggerNodeList;