import React, {useContext, useEffect, useState} from "react";
import Helper from "../../library/Helper";
import Cases from "../../api/caseEventApi";
import HttpCodes from "../../library/HttpCodes";
import {AuthContext, DisplayContext} from "../../ResponsiveApp";
import {TopMenuContext} from "../navigation/DolphinTopMenu";
import {NavigationContext} from "../navigation/DolphinNavigator";
import LinkBuilder from "../../domain/LinkBuilder";
import {LiftDataViewState} from "../lift/LiftManagementView";
import EventCaseLogListItem from "./EventCaseLogListItem";
import {ca} from "date-fns/locale";
import {SupportCaseStatus} from "../../domain/SystemTypes";
import NetsuiteCustomerSearchView from "../netsuite/NetsuiteCustomerSearchView";
import Customers from "../../api/customerApi";


const EventCaseEditor = ({case_id, onCloseEditor}) => {
    const {authorisation} = useContext(AuthContext)
    const display = useContext(DisplayContext)
    const {setTitleBarContent} = useContext(TopMenuContext)
    const { push_sidebar, navigate, nav_set_active, reset_home_sidebar, loading, setLoading} = useContext(NavigationContext)

    const [targetCase, setTargetCase] = useState(null);
    const [selectedCaseLogs, setSelectedCaseLogs] = useState([]);
    const [addingLogEntry, setAddingLogEntry] = useState(false);
    const [addingLogMsg, setAddingLogMsg] = useState("");
    const [saving, setSaving] = useState(false);
    const [selectingCustomer, setSelectingCustomer] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    useEffect(() => {
        const loader = async (case_id) => {
            await loadCase(case_id)
        }

        loader(case_id)
    }, [case_id])


    const loadCase = async (id) => {
        try {
            setLoading("Loading Support Case")
            let result = await Cases.loadCase(id)
            console.log("Case Logs Response: ", result)
            if (result.status === HttpCodes.HttpOK) {
                setTargetCase(result.data.case_header)
                setSelectedCaseLogs(result.data.case_logs)
            }
            else {
                setTargetCase(null)
                setSelectedCaseLogs([])
            }
        }
        catch(e) {
            console.log("Error loading support case: ", e)
            setTargetCase(null)
            setSelectedCaseLogs([])
        }
        finally {
            setLoading(null)
        }
    }

    const navigateToLift = (lift_id) => {
        LinkBuilder.updateDeviceActiveView(lift_id, LiftDataViewState.DataStatus)
        navigate(`/lift?device=${lift_id}`)
    }

    const navigateToRule = (rule_set_id) => {
        navigate(`/triggers/trigger?id=${rule_set_id}`)
    }

    const cancelNewLogEntry = () => {
        setAddingLogEntry(false)
        setAddingLogMsg("")
    }

    const updateCaseStatusInfo = async (status, target_user_id) => {
        try {
            setLoading("Setting Log Status")
            setSaving(true)
            let request = {
                case_id: case_id,
                case_status_id: status,
                target_user_id: target_user_id || targetCase.target_user_id,
            }
            console.log("Save Status Entry: ", request)
            let result = await Cases.saveCaseStatus(request)
            console.log("Save Status Entry Response: ", result)
            if (result.status === HttpCodes.HttpOK) {
                await loadCase(case_id)
            }
        }
        catch(e) {
            console.log("Error saving log status update: ", e)
        }
        finally {
            setLoading(null)
            setSaving(false)
        }
    }

    const saveNewLogEntry = async () => {
        try {
            setLoading("Saving Log Entry")
            setSaving(true)
            let log = {
                case_id: case_id,
                log_text: addingLogMsg,
                lift_event_id : null
            }
            console.log("Save Log Entry: ", log)
            let result = await Cases.saveCaseLog(log)
            console.log("Save Log Entry Response: ", result)
            if (result.status === HttpCodes.HttpOK) {
                setAddingLogEntry(false)
                await loadCase(case_id)
            }
        }
        catch(e) {
            console.log("Error saving log entry: ", e)
        }
        finally {
            setLoading(null)
            setSaving(false)
        }
    }


    const setInProgress = async () => {
        let account = authorisation.account
        await updateCaseStatusInfo(SupportCaseStatus.InProgress, account.userid)
    }

    const setResolved = async () => {
        await updateCaseStatusInfo(SupportCaseStatus.Resolved, null)
    }

    const setCancelled = async () => {
        await updateCaseStatusInfo(SupportCaseStatus.Cancelled, null)
    }

    const setEscalated = async () => {
        await updateCaseStatusInfo(SupportCaseStatus.Escalated, null)
    }

    const onSelectCustomer = async (customer) => {
        console.log("Selected Customer: ", customer)
        setSelectingCustomer(false)
        setSelectedCustomer(customer)
    }

    const assignCustomer = async () => {
        try {
            setLoading("Assigning Customer")
            let existingCustomer = await Customers.customerByNetsuiteId(selectedCustomer.entityid)
            console.log("Existing Customer: ", existingCustomer)
            let targetCustomerId = null
            let device_id = targetCase.target_lift_id


            if (existingCustomer.status === HttpCodes.HttpOK) {
                targetCustomerId = existingCustomer.data.customer_id
            }
            else if (existingCustomer.status === HttpCodes.HttpNoContent) {
                console.log("No existing customer found for ", selectedCustomer)
                let newCustomer = {
                    customer_id : null,
                    netsuite_customer_id: selectedCustomer.entityid,
                    contact_name : selectedCustomer.addressee || (selectedCustomer.salutation + " " + selectedCustomer.firstname + " " + selectedCustomer.lastname),
                    contact_phone: selectedCustomer.addrphone,
                    alt_contact_name: selectedCustomer.altname,
                    alt_contact_phone: selectedCustomer.altphone,
                    addr_line_1 : selectedCustomer.addr1,
                    addr_line_2 : selectedCustomer.addr2,
                    addr_town : selectedCustomer.city,
                    addr_county : selectedCustomer.state,
                    addr_postcode : selectedCustomer.zip,
                    country_id : 1,
                    geo_longitude : selectedCustomer.custentity_e3gc_shippinglongitude,
                    geo_latitude : selectedCustomer.custentity_e3gc_shippinglatitude,
                }

                console.log("New Customer: ", newCustomer)
                let saveResult = await Customers.save(newCustomer)
                console.log("Save Customer Response: ", saveResult)
                if (saveResult.status === HttpCodes.HttpOK) {
                    targetCustomerId = saveResult.data.id
                }
            }

            if (!targetCustomerId) {
                return ;
            }

            let result = await Cases.assignCustomerToCase(case_id, device_id, targetCustomerId)
            console.log("Assign Customer Response: ", result)
            if (result.status === HttpCodes.HttpOK) {
                await loadCase(case_id)
            }

        }
        catch(e) {
            console.log("Error assigning customer: ", e)
        }
        finally {
            setLoading(null)
        }
    }

    console.log("Support Case: ", targetCase)
    return (
        <div>
            <h3>[Support Case #{targetCase?.case_id || "-"}] {targetCase?.case_name}</h3>
            <button className={"small-button"} style={{marginLeft: '20px'}} onClick={onCloseEditor}>
                <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>close</span>Close
            </button>
            <button className={"small-button"} style={{marginLeft: '20px'}} onClick={() => loadCase(case_id)}>
                <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>refresh</span>Refresh
            </button>

            {(targetCase) &&
                <div className={"app-container"}>

                    {/*Main User Details*/}
                    <div className={"app-container-item"}>
                        <div className="lift-header-column">
                            <div className="section-header">Support Case Detail</div>
                            <div className="section-edit-row">
                                <label style={{width: '140px', display: 'inline-block'}}>Name</label>
                                <input type="text"
                                       style={{width: '280px'}}
                                       disabled={true}
                                       value={targetCase.case_name}
                                       onChange={(e) => {
                                       }}
                                />

                            </div>
                            <br/>
                            <div className="section-edit-row">
                                <label style={{width: '140px', display: 'inline-block'}}>Description</label>
                                <input type="text"
                                       style={{width: '280px'}}
                                       disabled={true}
                                       value={targetCase.case_description}
                                       onChange={(e) => {
                                       }}
                                />

                            </div>
                            <br/>
                            <div className="section-edit-row">
                                <label style={{width: '140px', display: 'inline-block'}}>Status</label>
                                <input type="text"
                                       style={{width: '180px'}}
                                       disabled={true}
                                       value={targetCase.case_status}
                                />
                            </div>
                            <br/>
                            <div className="section-edit-row">
                                <label style={{width: '140px', display: 'inline-block'}}>Target Lift</label>
                                <input type="text"
                                       style={{width: '280px'}}
                                       disabled={true}
                                       value={targetCase.lift_name || " - unnamed -"}
                                />
                                <span className="material-symbols-outlined" style={{cursor: 'pointer', marginLeft: '10px', verticalAlign: '-6px'}} onClick={() => navigateToLift(targetCase.target_lift_id)}>jump_to_element</span>
                            </div>
                            <br/>
                            <div className="section-edit-row">
                                <label style={{width: '140px', display: 'inline-block'}}>Product</label>
                                <input type="text"
                                       style={{width: '280px'}}
                                       disabled={true}
                                       value={targetCase.productname || " - missing -"}
                                />
                            </div>
                            <br/>

                            {(targetCase.rule_set_id) &&
                                <div className="section-edit-row">
                                    <label style={{width: '140px', display: 'inline-block'}}>Triggering Rule</label>
                                    <input type="text"
                                           style={{width: '280px'}}
                                           disabled={true}
                                           value={targetCase.rule_set_name || " - missing -"}
                                    />
                                    <span className="material-symbols-outlined" style={{cursor: 'pointer', marginLeft: '10px', verticalAlign: '-6px'}} onClick={() => navigateToRule(targetCase.rule_set_id)}>jump_to_element</span>
                                </div>
                            }
                            <br/>
                            {(targetCase.created_by) &&
                                <div className="section-edit-row">
                                    <label style={{width: '140px', display: 'inline-block'}}>Created By</label>
                                    <input type="text"
                                           style={{width: '280px'}}
                                           disabled={true}
                                           value={`${targetCase.created_user_firstname} ${targetCase.created_user_surname}`}
                                    />
                                </div>
                            }

                            {(!targetCase.created_by) &&
                                <div className="section-edit-row">
                                    <label style={{width: '140px', display: 'inline-block'}}>Created By</label>
                                    <input type="text"
                                           style={{width: '280px'}}
                                           disabled={true}
                                           value={"Dolphin System"}
                                    />
                                </div>
                            }

                            <br/>
                            <div className="section-edit-row">
                                <label style={{width: '140px', display: 'inline-block'}}>Created At</label>
                                <input type="text"
                                       style={{width: '180px'}}
                                       disabled={true}
                                       value={Helper.dbTimeToDisplayTime(targetCase.created_on)}
                                />
                            </div>

                            <br/>
                            {(targetCase.last_updated_by) &&
                                <>
                                    <div className="section-edit-row">
                                        <label style={{width: '140px', display: 'inline-block'}}>Last Updated By</label>
                                        <input type="text"
                                               style={{width: '280px'}}
                                               disabled={true}
                                               value={`${targetCase.updated_user_firstname} ${targetCase.updated_user_surname}`}
                                        />
                                    </div>
                                    <br/>
                                    <div className="section-edit-row">
                                        <label style={{width: '140px', display: 'inline-block'}}>Last Updated At</label>
                                        <input type="text"
                                               style={{width: '280px'}}
                                               disabled={true}
                                               value={Helper.dbTimeToDisplayTime(targetCase.last_updated_on)}
                                        />
                                    </div>
                                </>

                            }

                            <br/>
                            <div className="section-edit-row">
                                {(targetCase.case_status_id === SupportCaseStatus.WaitingResponse) &&
                                    <>
                                        <button className={"small-button"}  style={{marginLeft: '20px'}} onClick={setInProgress}>
                                            <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>check_circle</span>Acknowledge
                                        </button>
                                    </>
                                }

                                {(targetCase.case_status_id !== SupportCaseStatus.Resolved) &&
                                    <>
                                        <button className={"small-button"} style={{marginLeft: '20px'}} onClick={setResolved}>
                                            <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>check_circle</span>Set Resolved
                                        </button>
                                        <button className={"small-button"} style={{marginLeft: '20px'}} onClick={setCancelled}>
                                            <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>cancel</span>Cancel
                                        </button>
                                        <button className={"small-button"} style={{marginLeft: '20px'}} onClick={() => {
                                        }}>
                                            <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>person</span>Assign User
                                        </button>
                                    </>
                                }

                                {(targetCase.case_status_id === SupportCaseStatus.Resolved) &&
                                    <>
                                        <button className={"small-button"} style={{marginLeft: '20px'}} onClick={setInProgress}>
                                        <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>lock_open</span>Re-Open
                                        </button>
                                    </>
                                }


                            </div>

                        </div>
                        <div className="lift-header-column">
                            <div className="section-header">Customer</div>
                            {(!selectingCustomer && targetCase.lift_customer_id) &&
                                <>
                                    <div className="section-edit-row">
                                        <label style={{width: '120px', display: 'inline-block'}}>Netsuite Entity</label>
                                        <input type="text"
                                               style={{width: '180px'}}
                                               disabled
                                               value={`${targetCase.lift_netsuite_id} / ${targetCase.lift_netsuite_category}`}
                                        />
                                    </div>
                                    <br/>
                                    <div className="section-edit-row">
                                        <label style={{width: '120px', display: 'inline-block'}}>Contact Name</label>
                                        <input type="text"
                                               style={{width: '280px'}}
                                               disabled
                                               value={targetCase.lift_contact_name}
                                        />
                                    </div>
                                    <br/>
                                    <div className="section-edit-row">
                                        <label style={{width: '120px', display: 'inline-block'}}>Contact Phone #</label>
                                        <input type="text"
                                               style={{width: '180px'}}
                                               disabled
                                               value={targetCase.lift_contact_phone}
                                        />
                                    </div>
                                    <br/>
                                    <div className="section-edit-row">
                                        <label style={{width: '120px', display: 'inline-block'}}>Alt Contact Name</label>
                                        <input type="text"
                                               style={{width: '280px'}}
                                               disabled
                                               placeholder="alt contact name"
                                               value={targetCase.lift_alt_contact_name}
                                        />
                                    </div>
                                    <br/>
                                    <div className="section-edit-row">
                                        <label style={{width: '120px', display: 'inline-block'}}>Alt Phone #</label>
                                        <input type="text"
                                               style={{width: '180px'}}
                                               disabled
                                               placeholder="alt contact phone number"
                                               value={targetCase.lift_alt_contact_phone}
                                        />
                                    </div>
                                    <br/>
                                    <div className="section-edit-row">
                                        <label style={{width: '120px', display: 'inline-block', verticalAlign: 'top'}}>Shipping Address</label>
                                        <div className="non-editable-text" style={{display: 'inline-block'}}>
                                            {(targetCase.lift_addr1) && <div className="lift-address-text">{targetCase.lift_addr1}</div>}
                                            {(targetCase.lift_addr2) && <div className="lift-address-text">{targetCase.lift_addr2}</div>}
                                            {(targetCase.lift_addr3) && <div className="lift-address-text">{targetCase.lift_addr3}</div>}
                                            {(targetCase.lift_town) && <div className="lift-address-text">{targetCase.lift_town}</div>}
                                            {(targetCase.lift_addr_county) && <div className="lift-address-text">{targetCase.lift_addr_county}</div>}
                                            {(targetCase.lift_postcode) && <div className="lift-address-text">{targetCase.lift_postcode}</div>}
                                        </div>
                                    </div>
                                </>
                            }

                            {(!targetCase.lift_customer_id && selectedCustomer) &&
                                <div className={"bordered"}>
                                    <h4>Selected Customer</h4>
                                    <div><b>{selectedCustomer.entityid}</b></div>
                                    <div>{selectedCustomer.salutation} {selectedCustomer.firstname} {selectedCustomer.lastname}</div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: selectedCustomer.custentity_stiltz_bill_to_address}}/>
                                </div>
                            }

                            {(!selectingCustomer && !targetCase.lift_customer_id && !selectedCustomer) &&
                                <>
                                    <div className={"list-prominent-hdr"}>No Customer currently associated with Lift</div>
                                    <br/>
                                </>
                            }

                            {(!selectingCustomer && !targetCase.lift_customer_id) &&
                                <>
                                    <div className="section-edit-row">
                                        <button className={"small-button"} style={{marginLeft: '20px'}} onClick={() => setSelectingCustomer(true)}>
                                            <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>person_search</span>Select Customer
                                        </button>
                                        {(selectedCustomer) &&
                                            <button className={"small-button"} style={{marginLeft: '20px'}} onClick={assignCustomer}>
                                                <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>add</span>Assign to Lift
                                            </button>
                                        }
                                    </div>
                                </>
                            }

                            {(selectingCustomer) &&
                                <NetsuiteCustomerSearchView onCustomerSelect={(c) => onSelectCustomer(c)}/>
                            }


                        </div>
                    </div>
                    <div className={"app-container-item"}>
                        <div className="lift-header-column">
                            <div className="section-header">Support Case Logs</div>

                            {(!addingLogEntry) &&
                                <button className={"small-button"} style={{marginBottom: '20px'}} onClick={() => setAddingLogEntry(true)}>
                                    <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>add</span>Add Log Entry
                                </button>
                            }

                            {(addingLogEntry) &&
                                <>
                                    <div className="section-edit-row">
                                        <label style={{width: '140px', display: 'inline-block'}}>Enter Message</label>
                                    </div>

                                    <div className="section-edit-row">
                                        <textarea
                                               style={{width: '300px', height: '200px', textAlign: 'top', verticalAlign: 'top'}}
                                               value={addingLogMsg}
                                               onChange={(e) => setAddingLogMsg(e.target.value)}
                                        />
                                    </div>
                                    <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                        <button className={"small-button"} disabled={saving} style={{marginBottom: '20px'}} onClick={cancelNewLogEntry}>
                                            <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>cancel</span>Cancel
                                        </button>
                                        <button className={"small-button"} disabled={saving} style={{marginBottom: '20px'}} onClick={saveNewLogEntry}>
                                            <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>save</span>Save
                                        </button>
                                    </div>
                                </>
                            }

                            <div className="section-edit-row">
                                {(selectedCaseLogs.length > 0) &&
                                    <div style={{width: '400px'}}>
                                        {(selectedCaseLogs.map((log, index) => {
                                            return <EventCaseLogListItem key={`li_${index}`} item={log} index={index} total_length={selectedCaseLogs.length}/>
                                        }))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default EventCaseEditor;