import Helper from "../../library/Helper";
import React from "react";
import {ProductTypes} from "../../domain/SystemTypes";
import duo_logo from "../../assets/Duo-Hybrid-Black.png";
import trio_logo from "../../assets/Trio-Hybrid-Black.png";
import classic_logo from "../../assets/TCL-Black.png";


const NetsuiteCustomerSmallListItem = ({ item, index, total_length, onSelect}) => {

    if (!item) {
        return null
    }

    console.log("Netsuite customer: ", item) ;
    return (
        <div className={"list-item"} onClick={() => onSelect?.(item)}>
            <div className={"list-body"} >
                <div className={"list-title"} >
                    <div>{item.entityid}</div>
                </div>
                <div className={"list-summary"}>
                    <div>{item.salutation} {item.firstname} {item.lastname}</div>
                    <div dangerouslySetInnerHTML={{__html: item.custentity_stiltz_bill_to_address}}/>
                    <br/>

                </div>
                <div className={"list-footer"}>
                    {/*<div style={{color: 'black', marginTop: '5px'}}>Install Count {item.installcount}</div>*/}
                    {/*<div style={{marginBottom: '10px'}}>Created {Helper.dbTimeToDisplayTime(item.created)}</div>*/}
                    {/*<div style={{marginBottom: '10px'}}>Last Updated {Helper.dbTimeToDisplayTime(item.last_updated)}</div>*/}
                </div>
            </div>
            {/*<div className={"list-nav"}>→</div>*/}
        </div>
    )
}


export default NetsuiteCustomerSmallListItem;