import moment from "moment";
import HttpCodes from "../library/HttpCodes";
import IOApiDefinition from "../api/ioApi";


const _ioStore = {
    timestamp : null,
    loading : false,
    io : null
}

class IODefinitionStoreClass {

    io = async (forceRefresh = false) => {
        if (_ioStore.loading) {
            return _ioStore.io ;
        }

        if (!forceRefresh && _ioStore.io) {
            return _ioStore.io ;
        }

        try {
            _ioStore.loading = true ;
            let result = await IOApiDefinition.getIODefinitions()
            if (result.status === HttpCodes.HttpOK) {
                _ioStore.io = result.data ;
                _ioStore.timestamp = moment.utc().format();

                // console.log("Loaded IO Definitions - ", _ioStore.io) ;
            }
        }
        catch(e) {
            console.error("Problem loading IO Definitions - ", e.message);
        }
        finally {
            _ioStore.loading = false ;
        }

        return _ioStore.io ;
    }

    revisionIO = async (revisionNumber, forceRefresh = true) => {
        let io = await this.io(forceRefresh) ;
        if (!io) {
            return null ;
        }

        let target_rev = io.io_rev_map[revisionNumber] || io.io_rev_map["*"]
        switch (target_rev.io) {
            case "POST_0.3" : return io.fw_maps["v0_4"] ;
            case "POST_0.4" : return io.fw_maps["v0_4"] ;
            case "POST_0.5" : return io.fw_maps["v0_5"] ;
            case "POST_0.5.1" : return io.fw_maps["v0_5_1"] ;
            case "POST_0.5.2" : return io.fw_maps["v0_5_2"] ;
            default : return io.fw_maps["v0_5"] ;
        }
    }

    ioDefinitionMap = async (forceRefresh = true) => {
       return await this.io(forceRefresh) ;
    }
}

const IODefinitionStore = new IODefinitionStoreClass();
export default IODefinitionStore ;

