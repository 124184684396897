import ButtonGlyph from "../../../library/buttonGlyph";
import {ruleStatusBarState} from "../../../domain/RuleStatusBarState";
import Helper from "../../../library/Helper";
import {useNavigate} from "react-router-dom";
import {LiftDataViewState} from "../../lift/LiftManagementView";
import LinkBuilder from "../../../domain/LinkBuilder";


const TriggerDisplayItemView = ({triggerSet, title, hideInactive, onRuleEdit, onManageEvents, showConditions, showContacts, allowDetach, onDetach, showDevices}) => {
    const gotopage = useNavigate();

    const startEdit = (rule) => {
        onRuleEdit(rule) ;
    }

    const manageEvents = (rule) => {
        onManageEvents(rule) ;
    }

    const displayLift = (device_id) => {
        LinkBuilder.deviceView(gotopage, device_id, LiftDataViewState.DataNormal);
    }

    return (
        <div >
            {triggerSet.length === 0 && <span className="red-text">{`There are no ${title} attached`}</span>}
            {triggerSet.filter(l => hideInactive ? l.active : true).map((a, ai) => {
                return (
                    <div key={`grs-${ai}`} className="rule-item" >
                        <div className={`lift-list-status-bar-${ruleStatusBarState(a)}`} style={{display: 'inline-block'}}>
                            <div >
                                <div style={{display: 'inline-block'}}>
                                    <div style={{display: 'inline-block', width: '500px'}}>
                                        <div  className="trigger-display-item" style={{width: '450px', verticalAlign: 'top'}}><b>{a.rule_set_name}</b></div>
                                        <div style={{width: '150px', fontSize: '12px', marginTop: '10px'}}><b>{a.rule_event_type}</b></div>
                                        <div  style={{width: '450px', fontSize: '12px', marginTop: '10px', fontStyle: 'italic'}}><b>{a.rule_set_desc}</b></div>

                                        <div style={{marginLeft: '20px', marginTop: '10px'}}>
                                            <ButtonGlyph glyph={a.active ? "check_box" : "check_box_outline_blank"}
                                                         cn="material-symbols-rounded"
                                                         style={{fontSize: '24px', verticalAlign: 'middle', color : 'darkgray', width: '40px'}}/>
                                            <small><i>Active</i></small>
                                        </div>
                                    </div>


                                    {showConditions &&
                                        <div key={`grsd-${ai}`} style={{marginLeft: '20px', marginTop: '20px', display: 'inline-block', verticalAlign: 'top', borderLeft: '0.5px solid lightgray', width: '400px'}}>
                                            <div className="trigger-sub-item" style={{marginLeft: '5px'}}>Conditions</div>
                                            <div style={{}}>
                                                <div  style={{display: 'inline-block',width: '20px', fontSize: '12px'}}></div>
                                                <div  style={{display: 'inline-block',width: 'auto', fontSize: '12px'}}><b>{a.triggerSummary}</b></div>
                                            </div>

                                            <div>
                                                <div  style={{display: 'inline-block',width: 'auto', fontSize: '12px', marginLeft: '25px'}}>
                                                    {a.binary_conditions.map((pi, pix) => {
                                                        return <span key={`bc_${pix}`}><span className="material-symbols-outlined" style={{marginRight: '10px', verticalAlign : '-6px'}}>arrow_forward</span><small>{pi.item_label}&nbsp;{pi.condition_op}&nbsp;{pi.item_value}</small><br/></span>
                                                    })}

                                                    {a.analogue_conditions.map((pi, pix) => {
                                                        return <span key={`ac_${pix}`}><span className="material-symbols-outlined" style={{marginRight: '10px', verticalAlign : '-6px'}}>arrow_forward</span><small>{pi.item_label}&nbsp;{pi.condition_op}&nbsp;{pi.item_value}</small><br/></span>
                                                    })}
                                                </div>
                                            </div>

                                            {(a.rule_time_unit_id !== null) &&
                                                <div style={{marginTop: '10px'}}>
                                                    <div className="trigger-sub-item" style={{marginLeft: '5px'}}>Over Duration</div>
                                                    <div style={{marginLeft: '25px', fontSize: '14px', marginTop: '5px'}}>A period of {a.rule_duration} {a.rule_time_unit}</div>
                                                </div>
                                            }
                                        </div>
                                    }


                                    {showContacts &&
                                        <div key={`grsdc-${ai}`} style={{marginLeft: '20px', marginTop: '20px', display: 'inline-block', verticalAlign: 'top', borderLeft: '0.5px solid lightgray'}}>
                                            <div className="trigger-sub-item" style={{marginLeft: '5px'}}>Notification Contacts</div>

                                            {(a.notifiers === null) &&
                                                <div style={{marginLeft: '25px', fontSize: '12px', marginTop: '10px', color: 'red'}}>You haven't setup any contacts for notification yet</div>
                                            }

                                            {(a.notifiers !== null) &&
                                                <div>
                                                    {(a.notifiers.contacts.length > 0) &&
                                                        <div style={{padding: '3px', marginTop: '5px'}}>
                                                            <div  style={{display: 'inline-block',marginLeft: '15px', fontSize: '12px'}}><b>Individual Contacts</b></div>
                                                            {a.notifiers.contacts.map((c,cx) => {
                                                                return (
                                                                    <div key={`ctx_${cx}`}>
                                                                        <div style={{padding: '5px', marginTop: '5px'}}>
                                                                            <span style={{color: 'darkblue'}}>{c.firstname}&nbsp;{c.surname}</span>
                                                                        </div>

                                                                        <div style={{marginLeft: '10px'}}>
                                                                            {c.allow_phone_contact && <div><span className="material-symbols-outlined" style={{marginLeft: '25px',marginRight: '10px', verticalAlign : '-6px'}}>sms</span> on <b style={{color: 'cornflowerblue'}}>{c.contact_phone || '(no number specified)'}</b></div>}
                                                                            {c.allow_email_contact && <div><span className="material-symbols-outlined" style={{marginLeft: '25px',marginRight: '10px', verticalAlign : '-6px'}}>email</span> at <b style={{color: 'cornflowerblue'}}>{c.email_address}</b></div>}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    }
                                                    {(a.notifiers.groups.length > 0) &&
                                                        <div style={{padding: '3px', marginTop: '5px'}}>
                                                            <div  style={{display: 'inline-block',marginLeft: '15px', fontSize: '12px'}}><b>Contact Groups</b></div>
                                                            {a.notifiers.groups.map((c,cx) => {
                                                                return (
                                                                    <div key={`gtx_${cx}`}>
                                                                        {(c.rule_contact_type_id === 1) && <span><span className="material-symbols-outlined" style={{marginLeft: '25px',marginRight: '10px', verticalAlign : '-6px'}}>email</span><small><span style={{color: 'darkblue'}}>{c.contact_group_name}</span></small><br/></span>}
                                                                        {(c.rule_contact_type_id === 2) && <span><span className="material-symbols-outlined" style={{marginLeft: '25px',marginRight: '10px', verticalAlign : '-6px'}}>sms</span><small><span style={{color: 'darkblue'}}>{c.contact_group_name}</span></small><br/></span>}
                                                                        {(c.rule_contact_type_id === 3) && <span><span className="material-symbols-outlined" style={{marginLeft: '25px',marginRight: '5px', verticalAlign : '-6px'}}>email</span><span className="material-symbols-outlined" style={{marginRight: '10px', verticalAlign : '-6px'}}>sms</span><small><span style={{color: 'darkblue'}}>{c.contact_group_name}</span></small><br/></span>}
                                                                        {(c.rule_contact_type_id === 4) && <span><span className="material-symbols-outlined" style={{marginLeft: '25px',marginRight: '5px', verticalAlign : '-6px'}}>sms</span><i style={{color: 'blue'}}>otherwise</i><span className="material-symbols-outlined" style={{marginRight: '10px', verticalAlign : '-6px'}}>email</span><small><span style={{color: 'darkblue'}}>{c.contact_group_name}</span></small><br/></span>}
                                                                        {(c.rule_contact_type_id === 5) && <span><span className="material-symbols-outlined" style={{marginLeft: '25px',marginRight: '5px', verticalAlign : '-6px'}}>email</span><i style={{color: 'blue'}}>otherwise</i><span className="material-symbols-outlined" style={{marginRight: '10px', verticalAlign : '-6px'}}>sms</span><small><span style={{color: 'darkblue'}}>{c.contact_group_name}</span></small><br/></span>}

                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>

                                    }
                                </div>
                            </div>
                            {/*srdl.rule_set_id, d.device_id, d.display_name, d.product_id, sp.productname, sc.contact_name, sc.contact_phone*/}

                            {(showDevices && a.devices && a.devices.length > 0) &&
                                <div>
                                    <h5>Devices assigned this rule</h5>
                                    <table>
                                        <thead>
                                        <tr key={`lde_hdr`}>
                                            <th style={{width: '50px'}}>#</th>
                                            <th style={{width: '200px'}}>Lift</th>
                                            <th style={{width: '120px'}}>Product</th>
                                            <th style={{width: '200px'}}>Contact Name</th>
                                            <th style={{width: '100px'}}>Contact Phone</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {(a.devices.map((d,dx) => {
                                            return (
                                                <tr key={`lde_${dx}`}>
                                                    <td>
                                                        <div className="stz-image-button" ><span className="material-icons" title="View Data" style={{verticalAlign : '0px', fontSize: '24px'}} onClick={() => displayLift(d.device_id)}>query_stats</span></div>
                                                    </td>
                                                    <td>{d.display_name || "- unnamed -"}</td>
                                                    <td>{d.productname || "- no product -"}</td>
                                                    <td>{d.contact_name || "- no contact -"}</td>
                                                    <td>{d.contact_phone || "- no number - "}</td>
                                                </tr>
                                            )
                                        }))
                                        }
                                        </tbody>
                                    </table>

                                </div>
                            }

                            <div style={{marginTop: '10px', paddingBottom : '5px', marginLeft: '20px'}}>
                                {(allowDetach) &&
                                    <button onClick={() => onDetach(a)}>
                                        <span className="material-symbols-outlined" style={{verticalAlign: '-7px', paddingRight: '8px'}}>add_link</span>Detach from lift
                                    </button>
                                }

                                {(onRuleEdit) &&
                                    <button onClick={() => startEdit(a)}>
                                        <span className="material-symbols-outlined" style={{verticalAlign: '-7px', paddingRight: '8px'}}>settings</span>Configure
                                    </button>
                                }

                                {(onManageEvents) &&
                                    <button onClick={() => manageEvents(a)}>
                                        <span className="material-symbols-outlined" style={{verticalAlign: '-7px', paddingRight: '8px'}}>insights</span>Manage Events
                                    </button>
                                }

                            </div>
                            <hr/>
                        </div>
                    </div>
                );

            })}
        </div>

    )
}

export default TriggerDisplayItemView ;