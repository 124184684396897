import React, {useContext} from "react";
import {DisplayContext} from "../../../ResponsiveApp";
import {LiftDataContext} from "../LiftDataNavigatorContext";
import {LiftEngineerPreV3IOView} from "../pre_v3_views/LiftEngineerPreV3IOView";
import {LiftEngineerIOView} from "../LiftEngineerIOView";
import {LiftEngineerV4IOView} from "../v4_views/LiftEngineerV4IOView";
import {LiftEngineerV5IOView} from "../v5_views/LiftEngineerV5IOView";


const LiftStatusRawActiveStatus = () => {
    const {dataViewSet} = useContext(LiftDataContext) ;

    return (
        <div className="lv-raw">
            {(dataViewSet.current?.fw_version === 1) &&
                <LiftEngineerPreV3IOView />
            }

            {(dataViewSet.current?.fw_version === 2) &&
                <LiftEngineerIOView />
            }

            {/*Handle V4 & V5 data in same view*/}
            {(dataViewSet.current?.fw_version === 3) &&
                <LiftEngineerV4IOView />
            }

            {(dataViewSet.current?.fw_version >= 4) &&
                <LiftEngineerV5IOView />
            }
        </div>

    )
}

export default LiftStatusRawActiveStatus;