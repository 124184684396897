

const Permissions = {
    // Items up to 100 reserved for menus
    MenuSearch      : 1,
    MenuAdmin       : 2,
    MenuTriggers    : 3,
    MenuEvents      : 4,
    MenuAnalytics   : 5,
    MenuManageSims  : 6,
    MenuProducts    : 7,
    MenuLiveConnect : 8,
    MenuNewLift     : 9,
    MenuCustomers   : 10,
    MenuDealers     : 11,
    MenuSafety      : 12,

    // Item 100-199 reserved for administration
    AdminViewUsers             : 100,
    AdminViewUserDetails       : 101,
    AdminViewRolesPermissions  : 102,
    AdminViewContactGroups     : 103,
    AdminDeleteUser            : 104,

    // Items 200-299 reserved for search
    SearchByLift             : 200,
    SearchByCustomer         : 201,
    SearchByDealer           : 202,
    SearchByInstaller        : 203,
    SearchManufacturing      : 204,
    SearchIncludeDeleted     : 205,

    // Items 300-399 reserved for triggers
    TriggerManageSystem        : 300,
    TriggerManageGlobal        : 301,
    TriggerManageDevice        : 302,
    TriggerBuilder             : 303,

    // Items 400-499 reserved for misc
    ViewLiftDetails           : 400,
    ViewCustomerDetails       : 401,
    ViewDealerDetails         : 402,
    ViewTriggerDetails        : 403,
    ViewSupportCaseDetails    : 404,
    ViewProductDetails        : 405,

    // Items 500-599 reserved for manage sims
    SIMManagement            : 500,
    SIMViewDataUsage         : 501,
    SIMSuspendSIM            : 502,
    SIMActivateSIM           : 503,
    SIMDeactivateSIM         : 504,

    // Items 600-699 reserved for lift access
    LiftManagement           : 600,
    LiftLiveConnect          : 601,
    AccessNetsuite           : 602,

    // Items 700-799 reserved for misc functions
    ManageCustomers          : 700,
    ManageDealers            : 701,
    ManageProducts           : 702,
    ManageSupport            : 703,


    DebugPermissionTest      : 9999,
}

export default Permissions;
