import React, { useState } from 'react';

const BasicFilterInput = ({ onFilter , auto_filter = true}) => {
    const [filterText, setFilterText] = useState('');

    const handleFilterChange = (e) => {
        setFilterText(e.target.value);
        if (auto_filter) {
            onFilter(e.target.value);
        }
    };

    const handleFilterClick = () => {
        onFilter(filterText);
    };

    const clearFilter = () => {
        setFilterText('');
        onFilter('');
    }

    return (
        <div className="filter-container">
            <button className="filter-clear-button" onClick={clearFilter} disabled={filterText === ''}>
                <span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px'}}>cancel</span>
            </button>
            <input
                type="text"
                className="filter-input"
                value={filterText}
                onChange={handleFilterChange}
                placeholder="Enter filter text..."
            />

            {(!auto_filter) &&
                <button className="filter-search-button" onClick={handleFilterClick} disabled={filterText === ''}>
                    <span className="material-icons" style={{verticalAlign: '-7px'}}>search</span>
                </button>
            }

        </div>
    );
};

export default BasicFilterInput;