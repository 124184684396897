import React from "react";


const emptyFunc = () => {};


const numericOperators = [
    {key: "EQ", label: "Equal to"},
    {key: "NE", label: "Not Equal to"},
    {key: "GT", label: "Greater Than"},
    {key: "GE", label: "Greater Than or Equal to"},
    {key: "LT", label: "Less Than"},
    {key: "LE", label: "Less Than or Equal to"},
]

const textOperators = [
    {key: "EQ", label: "Equal to"},
    {key: "NE", label: "Not Equal to"},
    {key: "LIKE", label: "Includes"},
    {key: "NOT_LIKE", label: "Does Not Include"},
]

const TriggerConditionList = ({ trigger, ioDefinitions, chooseParen = emptyFunc, chooseJoin = emptyFunc, setEditValue = emptyFunc, removeTriggerItem = emptyFunc, setEditOperator = emptyFunc }) => {

    const analogueEditor = (idx, item) => {
        let an = item.target ;

        let body = <></>
        if (an.is_lookup) {
            body = (
                <ul className={"lookup-list"} style={{marginLeft: '20px', paddingTop: '10px'}}>
                    {Object.keys(an.lookup).map((k, kidx) => {
                        let lu = an.lookup[k];
                        let scc = lu === item.value ? "fw-hl-cell-sel" : "fw-hl-cell"
                        return (
                            <li key={kidx} onClick={() => setEditValue(idx, lu)}><div className={scc}>[{k}] - {lu || "- no description -"}</div></li>
                        )
                    })
                    }

                </ul>
            )
        }
        else {
            switch (an.datatype) {
                case "int":
                    body = (
                        <div className="section-edit-row">
                            <select style={{marginRight: '10px'}} value={item.operator || ""} onChange={(e) => setEditOperator(idx, e.target.value)}>
                                {numericOperators.map((op, opidx) => {
                                        return (
                                            <option key={opidx} value={op.key}>{op.label}</option>
                                        )
                                    }
                                )}
                            </select>

                            <input type={"number"}
                                   style={{width: '100px'}}
                                   value={item.value || ""}
                                   onChange={(e) => setEditValue(idx, e.target.value)}
                            />
                        </div>
                    )
                    break ;
                default :
                    body = (
                        <div className="section-edit-row">
                            <label style={{paddingRight: '10px', display: 'inline-block'}}>Value</label>
                            <input type={"text"}
                                   style={{width: '100px'}}
                                   value={item.value || ""}
                                   onChange={(e) => setEditValue(idx, e.target.value)}
                            />
                        </div>
                    )
                    break ;
            }
        }

        return (
            <div style={{marginLeft: '15px'}}>
                <div key={idx} style={{padding: '10px'}} className={"fw-item-editing"}>
                    <div className={"fw-item-title"}>{an.label}</div>

                    {body}

                    <div style={{paddingTop: '10px'}}>
                        <span className="material-icons" style={{color: 'red', fontSize: '32px', cursor: 'pointer'}} onClick={() => removeTriggerItem(idx, "ANALOGUE")}>delete</span>
                        <div className={"fw-item-bit"} style={{display: 'inline-block', float: 'right'}}>
                            <div className={"fw-item-desc"}>{an.key}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{padding: '5px'}}>
            <div className="item-container-list">
                {trigger.raw_structure.map((item, idx) => {
                    switch (item.type) {
                        case "LEFT_PAREN":
                            return (
                                <div key={idx} className={"fw-item-bracket"} style={{marginLeft: '15px', paddingTop: '20px'}} >
                                    <div>
                                        <div className={"LEFT" === item.applied ? "fw-item-bracket-lbl fw-item-bracket-sel" : "fw-item-bracket-lbl fw-item-bracket-nosel"} onClick={() => chooseParen(idx, "LEFT")}><b>(</b></div>
                                    </div>
                                </div>
                            )
                        case "RIGHT_PAREN":
                            return (
                                <div key={idx} className={"fw-item-bracket"} style={{marginLeft: '15px', paddingTop: '20px'}} >
                                    <div>
                                        <div className={"RIGHT" === item.applied ? "fw-item-bracket-lbl fw-item-bracket-sel" : "fw-item-bracket-lbl fw-item-bracket-nosel"} onClick={() => chooseParen(idx, "RIGHT")}><b>)</b></div>
                                    </div>
                                </div>
                            )
                        case "BOTH_PAREN":
                            return (
                                <div key={idx} className={"fw-item-bracket"} style={{marginLeft: '15px'}} >
                                    <div>
                                        <div className={"LEFT" === item.applied ? "fw-item-bracket-lbl fw-item-bracket-sel" : "fw-item-bracket-lbl fw-item-bracket-nosel"} onClick={() => chooseParen(idx, "LEFT")}><b>(</b></div>
                                        <div className={"RIGHT" === item.applied ? "fw-item-bracket-lbl fw-item-bracket-sel" : "fw-item-bracket-lbl fw-item-bracket-nosel"} onClick={() => chooseParen(idx, "RIGHT")}><b>)</b></div>
                                    </div>
                                </div>
                            )
                        case "JOIN":
                            return (
                                <div key={idx} className={"fw-item-join"} style={{marginLeft: '15px', paddingTop: '5px'}} >
                                    <div>
                                        <div className={"AND" === item.applied ? "fw-item-join-lbl fw-item-join-sel" : "fw-item-join-lbl fw-item-join-nosel"}
                                             style={{padding: '4px'}} onClick={() => chooseJoin(idx, "AND")}><b>AND</b></div>
                                        <div className={"OR" === item.applied ? "fw-item-join-lbl fw-item-join-sel" : "fw-item-join-lbl fw-item-join-nosel"}
                                             style={{padding: '4px'}} onClick={() => chooseJoin(idx, "OR")}><b>OR</b></div>
                                    </div>
                                </div>
                            )
                        case "ADC":
                            let adc = item.target;
                            let value = item.value;
                            let st = value ? "fw-item-editing" : "fw-item-editing-inv";
                            let icon = value ? "verified" : "warning";
                            let iconc = value ? "green" : "orange";
                            return (
                                <div style={{marginLeft: '15px'}}>
                                    <div key={idx} className={st} >
                                        <div>
                                            <div style={{display: 'inline-block'}}>
                                                <div className={"fw-item-title"}>{adc.desc || "- no description -"}</div>

                                                <div>
                                                    <div style={{display: 'inline-block'}}>
                                                        <span className="material-icons" style={{color: `${iconc}`, fontSize: '32px'}}>{icon}</span>
                                                    </div>
                                                    <div style={{display: 'inline-block'}}>
                                                        <div className={"HIGH" === value ? "fw-hl-cell-sel" : "fw-hl-cell"} onClick={() => setEditValue(idx, "HIGH")}>
                                                            <div style={{display: 'inline-block'}}>{adc.hl || "- n/a -"}</div>
                                                        </div>
                                                        <div className={"LOW" === value ? "fw-hl-cell-sel" : "fw-hl-cell"} onClick={() => setEditValue(idx, "LOW")}>
                                                            <div style={{display: 'inline-block'}}>{adc.ll || "- n/a -"}</div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            <div style={{paddingTop: '10px'}}>
                                                <span className="material-icons" style={{color: 'red', fontSize: '32px', cursor: 'pointer'}} onClick={() => removeTriggerItem(idx, "ADC")}>delete</span>
                                                <div className={"fw-item-bit"} style={{display: 'inline-block', float: 'right'}}>
                                                    <div style={{fontSize: '12px'}}>{adc.adc} bit {adc.bit}</div>
                                                    <div className={"fw-item-desc"}>{adc.io_id}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )

                        case "ANALOGUE" :
                            return analogueEditor(idx, item);

                    }

                })}
            </div>
        </div>
    )
}

export default TriggerConditionList;